const KpBreadCrumb = ({ title }: { title: string }) => {
    return (
        <div>
            <div className="intro-y mt-5 items-center">
                <h2 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                    {title}
                </h2>
                <hr className="mdg-bg-gardient-mode mt-2 h-1 md:w-80 lg:w-80 xl:w-80 2xl:w-80 rounded-[20px]" />
            </div>
        </div>
    );
};

export default KpBreadCrumb;
