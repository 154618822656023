import React from "react";
import Head from "../../../Layouts/Landing/Head";
import UtilsService from "../../../Services/UtilsService";

const Maintenance: React.FC = () => {
    return (
        <>
            <Head></Head>
            <div className="container">
                <div className="error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left">
                    <div className="-intro-x lg:mr-20 lg:text-right xl:text-right">
                        <img
                            alt="Not found image"
                            className="h-auto w-image-mobil"
                            src={UtilsService.getPulicImage(
                                "/dist/image/Maint_Loop_0.gif",
                            )}
                        />
                    </div>
                    <div className="mt-10 text-white lg:mt-0">
                        <div className="intro-x text-5xl font-medium">
                            Le site en maintenance !
                        </div>
                        <div className="intro-x mt-5 text-xl font-medium lg:text-3xl">
                            Nous arrivons bientôt
                        </div>
                        <div className="intro-x mt-3 text-lg">
                            Nous résoudrons ce problème en 24/7 heures.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Maintenance;
