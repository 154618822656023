import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbV2, {
    Ariane,
} from "../../../../../Layouts/Account/BreadCrumb/BreadCrumbV2";
import MerchantCategoryForm from "./MerchantCategoryForm";

const MerchantCategoryNew = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/merchant-category",
            name: "Categorie",
        },
        {
            path: "",
            name: id ? "Edit" : "Nouveau",
        },
    ];

    return (
        <div>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-10 bg-white p-2 rounded text-lg font-medium">
                {id
                    ? "Modifier une categorie"
                    : "Ajouter une nouvelle categorie"}
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    <button
                        className="btn btn-perfect mr-2"
                        onClick={() => navigate("/admin/merchant-category")}
                    >
                        Liste
                    </button>
                </div>
                <div className="intro-y col-span-12 lg:col-span-12">
                    <MerchantCategoryForm id={id ? Number(id) : undefined} />
                </div>
            </div>
        </div>
    );
};

export default MerchantCategoryNew;
