import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import PaymentForm from "./PaymentForm";

const Payment = () => {
    const { translate } = translator();
    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <KpBreadCrumb
                    title={translate("Settings", "Payment")}
                ></KpBreadCrumb>

                <div className="grid grid-cols-12 gap-6 mt-7">
                    <PaymentForm></PaymentForm>
                </div>
            </div>
        </main>
    );
};

export default Payment;
