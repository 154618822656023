import { useState } from "react";
import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import { OTransactionCount } from "../../../Models/Transaction";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    childs: ItemListProps[];
    transactionCount?: OTransactionCount;
};

const NavAdmin = ({
    name,
    icon,
    childs,
    path,
    transactionCount,
}: ItemListProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { translate } = translator();
    const isTransact =
        path === "/admin/withdrawal-request" ||
        path === "/admin/transactions" ||
        path === "/admin/all-transactions" ||
        path === "/admin/deposit-request";
    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    const getCountValue = (path: string) => {
        let result = 0;
        switch (path) {
            case "/admin/withdrawal-request":
                result = transactionCount?.withdraw || 0;
                break;
            case "/admin/deposit-request":
                result = transactionCount?.deposit || 0;
                break;
            case "/admin/transactions":
                result = transactionCount?.all || 0;
                break;
            case "/admin/all-transactions":
                result = transactionCount?.all_transactions || 0;
                break;
        }

        return result;
    };
    return (
        <>
            <li>
                <NavLink
                    to={
                        childs && childs.length > 0
                            ? `javascript:void(0)`
                            : path
                    }
                    className={(navData) =>
                        navData.isActive
                            ? expanded
                                ? "side-menu side-menu--open"
                                : "side-menu side-menu--active"
                            : "side-menu"
                    }
                    onClick={(e) => {
                        toggleExanded(e);
                    }}
                >
                    <div className="side-menu__icon">{icon}</div>
                    {isTransact && transactionCount && (
                        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                            {getCountValue(path)}
                        </div>
                    )}
                    <div className="side-menu__title">
                        {translate(".", name)}
                        {childs && childs.length > 0 && (
                            <div className="side-menu__sub-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="chevron-down"
                                    data-lucide="chevron-down"
                                    className="lucide lucide-chevron-down"
                                >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </div>
                        )}
                    </div>
                </NavLink>
                {childs && childs.length > 0 && (
                    <ul
                        className={
                            expanded ? "side-menu__sub-open block pl-3" : ""
                        }
                    >
                        {childs.map((child, i) => (
                            <NavAdmin
                                key={i}
                                name={child.name}
                                icon={child.icon}
                                path={child.path}
                                childs={child.childs}
                                transactionCount={transactionCount}
                            ></NavAdmin>
                        ))}
                    </ul>
                )}
            </li>
        </>
    );
};

export default NavAdmin;
