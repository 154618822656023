import { useState } from "react";
import AsyncSelect from "react-select/async";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import PartenarUser from "../../../Models/UserPartenar";
import useTheme from "../../../Services/Hooks/useTheme";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import UserService from "../../../Services/User/UserService";

interface SelectInputUserProps {
    id?: string;
    components?: any;
    onChange?: (value: any) => void;
    required?: boolean;
    no_parrain?: boolean;
    is_franchise?: boolean;
    is_role_user?: boolean;
    required_country?: boolean;
    franchise_country?: string;
}

const SelectInputUser = ({
    id,
    components,
    onChange,
    required,
    no_parrain,
    is_franchise,
    is_role_user,
    required_country,
    franchise_country,
}: SelectInputUserProps) => {
    const { mode } = useTheme();

    const [isLoading, setIsLoading] = useState(false);

    const mapOptions = (user: PartenarUser): DOption => {
        return {
            label: `${user.last_name} ${user.first_name} (${user.uid} - ${user.email})`,
            value: `${user.uid}`,
        };
    };

    const getPartenar = async (search: string) => {
        try {
            if (!!no_parrain) {
                const res = await UserService.getNoParrain();
                return res.map((elt: any) => mapOptions(elt));
            } else if (!!is_franchise && !!!required_country) {
                const res = await UserService.getFranchise(search);
                return res.map((elt: any) => mapOptions(elt));
            } else if (
                !!is_franchise &&
                franchise_country &&
                !!required_country
            ) {
                const res = await UserService.getSalesManagerByCountry(
                    franchise_country,
                    search,
                );
                return res.map((elt: any) => mapOptions(elt));
            } else if (!!is_role_user) {
                const res = await UserService.searchRoleUser(search);
                return res.map((elt: any) => mapOptions(elt));
            } else {
                const res = await UserService.getPartenar(search);
                return res.map((elt: any) => mapOptions(elt));
            }
        } catch (err: any) {
            return [];
        }
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: any[]) => void,
    ) => {
        setIsLoading(true);
        if (is_franchise === true) {
            setTimeout(async () => {
                // if (inputValue.length >= 3) {
                callback(await getPartenar(inputValue));
                // }

                setIsLoading(false);
            }, 1000);
        } else {
            setTimeout(async () => {
                if (inputValue.length >= 3) {
                    callback(await getPartenar(inputValue));
                }

                setIsLoading(false);
            }, 1000);
        }
    };

    return (
        <AsyncSelect
            id={id}
            loadOptions={loadOptions}
            className="bg-select-view-other"
            components={components}
            onChange={onChange}
            styles={{
                control: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              borderRadius: "0.5rem",

                              background: "transparent",
                              color: "rgb(30 41 59)",
                              "input:focus": {
                                  boxShadow: "none",
                              },
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              background: "transparent",
                              color: "#FFF",
                              "input:focus": {
                                  boxShadow: "none",
                              },
                          },
                option: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              background: "#fff",
                              color: "#000",
                              "&:hover": {
                                  color: "#fff",
                                  background: "#daa520",
                              },
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              background: "#0f0f0f",
                              color: "#FFF",
                              "&:hover": {
                                  color: "#fff",
                                  background: "#daa520",
                              },
                          },
            }}
            required={!!required}
            // isDisabled={!(!!required_country && franchise_country)}
        />
    );
};

export default SelectInputUser;
