import { createContext, useContext } from "react";
import useModal from "./useModal";
import Modal from "./Modal";

interface ModalContextType {
    modal: boolean;
    handleModal: (content?: any) => void;
    modalContent: any;
    animation: string;
}
const ModalContext = createContext<ModalContextType>({
    modal: false,
    handleModal: (content?: any) => {
        return false;
    },
    modalContent: null,
    animation: "animate__animated animate__fadeInDown",
});

const ModalProvider = ({ children }: { children: JSX.Element }) => {
    const { modal, handleModal, modalContent, animation } = useModal();
    return (
        <ModalContext.Provider
            value={{ modal, handleModal, modalContent, animation }}
        >
            <Modal />
            {children}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };
export const useModalContext = () => useContext(ModalContext);
