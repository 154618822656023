import "animate.css";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import UtilsService from "../../../Services/UtilsService";
import LogoutSvg from "../Svg/LogoutSvg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Dropdown = () => {
    const { user } = useAuthentication();
    const navigate = useNavigate();
    const { translate } = translator();
    const [show, setShow] = useState<boolean>(false);
    const user_pics =
        user && user.photo
            ? UtilsService.getPhotoUrl(user.photo)
            : UtilsService.getPulicImage("/dist/image/account.png");
    return (
        <>
            <button
                type="button"
                className="bg-transparent border-none use-tooltip-profil"
                onClick={() => {
                    setShow(!show);
                }}
            >
                <img
                    alt="User Dropdown"
                    className="rounded-full w-8 h-8 object-fill"
                    src={user_pics}
                />
            </button>
            <Tooltip
                anchorSelect=".use-tooltip-profil"
                place="bottom"
                events={["click"]}
                className="animate__animated animate__backInDown mb-right mb-top mb-left"
                style={{
                    zIndex: "1000000000000000000",
                    backgroundColor: "transparent",
                    height: "auto",
                    position: "relative",
                    top: "10px !important",
                    // boxShadow: "0px 3px 20px #00000030",
                    width: "250px",
                }}
            >
                <ul className="bg-primary text-white p-2 rounded shadow">
                    <li
                        className="cursor-pointer hover:text-[#daa520] mb-3 mb-text-left"
                        role="button"
                    >
                        <div className="font-medium capitalize">
                            {user?.user_name}
                        </div>
                        <div className="mt-0.5 text-xs text-white/70 dark:text-slate-500">
                            {user?.email}
                        </div>
                    </li>
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/settings/general"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="settings"
                                data-lucide="settings"
                                className="lucide lucide-settings mr-2"
                            >
                                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                            {translate("Profil", "Setting_Security")}
                        </NavLink>
                    </li>{" "}
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/loyalty"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="gift"
                                data-lucide="gift"
                                className="lucide lucide-gift block mr-2"
                            >
                                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                                <rect x="2" y="7" width="20" height="5"></rect>
                                <line x1="12" y1="22" x2="12" y2="7"></line>
                                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                            </svg>
                            {translate("Profil", "Fidelity")}
                        </NavLink>
                    </li>{" "}
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/settings/referrals"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 1920 1792"
                                className="mr-2"
                            >
                                <path
                                    fill="currentColor"
                                    d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5zM640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181zm704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181z"
                                />
                            </svg>
                            {translate("Profil", "Affiliation")}
                        </NavLink>
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/governances"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                fill="none"
                                className="text-white fill-white mr-2"
                            >
                                <path d="M18 13h-.68l-2 2h1.91L19 17H5l1.78-2h2.05l-2-2H6l-3 3v4c0 1.1.89 2 1.99 2H19c1.1 0 2-.89 2-2v-4l-3-3zm-1-5.05l-4.95 4.95-3.54-3.54 4.95-4.95L17 7.95zm-4.24-5.66L6.39 8.66c-.39.39-.39 1.02 0 1.41l4.95 4.95c.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41L14.16 2.3c-.38-.4-1.01-.4-1.4-.01z" />
                            </svg>
                            {translate("Governance", "Governance")}
                        </NavLink>
                    </li>
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/ecosystem"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                className="mr-2"
                            >
                                <path
                                    fill="currentColor"
                                    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8zm5.2 5.3c.4 0 .7.3 1.1.3c-.3.4-1.6.4-2-.1c.3-.1.5-.2.9-.2zM1 8c0-.4 0-.8.1-1.3c.1 0 .2.1.3.1c0 0 .1.1.1.2c0 .3.3.5.5.5c.8.1 1.1.8 1.8 1c.2.1.1.3 0 .5c-.6.8-.1 1.4.4 1.9c.5.4.5.8.6 1.4c0 .7.1 1.5.4 2.2C2.7 13.3 1 10.9 1 8zm7 7c-.7 0-1.5-.1-2.1-.3c-.1-.2-.1-.4 0-.6c.4-.8.8-1.5 1.3-2.2c.2-.2.4-.4.4-.7c0-.2.1-.5.2-.7c.3-.5.2-.8-.2-.9c-.8-.2-1.2-.9-1.8-1.2s-1.2-.5-1.7-.2c-.2.1-.5.2-.5-.1c0-.4-.5-.7-.4-1.1c-.1 0-.2 0-.3.1s-.2.2-.4.1c-.2-.2-.1-.4-.1-.6c.1-.2.2-.3.4-.4c.4-.1.8-.1 1 .4c.3-.9.9-1.4 1.5-1.8c0 0 .8-.7.9-.7s.2.2.4.3c.2 0 .3 0 .3-.2c.1-.5-.2-1.1-.6-1.2c0-.1.1-.1.1-.1c.3-.1.7-.3.6-.6c0-.4-.4-.6-.8-.6c-.2 0-.4 0-.6.1c-.4.2-.9.4-1.5.4C5.2 1.4 6.6 1 8 1h.8c-.6.1-1.2.3-1.6.5c.6.1.7.4.5.9c-.1.2 0 .4.2.5s.4.1.5-.1c.2-.3.6-.4.9-.5c.4-.1.7-.3 1-.7c0-.1.1-.1.2-.2c.6.2 1.2.6 1.8 1c-.1 0-.1.1-.2.1c-.2.2-.5.3-.2.7c.1.2 0 .3-.1.4c-.2.1-.3 0-.4-.1s-.1-.3-.4-.3c-.1.2-.4.3-.4.6c.5 0 .4.4.5.7c-.6.1-.8.4-.5.9c.1.2-.1.3-.2.4c-.4.6-.8 1-.8 1.7s.5 1.4 1.3 1.3c.9-.1.9-.1 1.2.7c0 .1.1.2.1.3c.1.2.2.4.1.6c-.3.8.1 1.4.4 2c.1.2.2.3.3.4c-1.3 1.4-3 2.2-5 2.2z"
                                />
                            </svg>
                            {translate("Profil", "Ecosystem")}
                        </NavLink>
                    </li>{" "}
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li className="cursor-pointer hover:text-[#daa520]">
                        <NavLink
                            to="/logout"
                            className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                        >
                            <LogoutSvg />
                            {translate("Head", "Logout")}
                        </NavLink>
                    </li>
                </ul>
            </Tooltip>
            {/* <div
                className={
                    show
                        ? "block relative animate__animated animate__backInDown"
                        : "animate__animated animate__backOutUp"
                }
            >
                <div className="w-[250px] absolute right-0 top-4 bg-transparent z-50">
                    <div className="bg-primary p-2 w-[250px] rounded">
                        <ul className="bg-primary text-white">
                            <li
                                className="pointer"
                                role="button"
                                onClick={() =>
                                    navigate("/settings/profile/detail")
                                }
                            >
                                <div className="font-medium capitalize">
                                    {user?.user_name}
                                </div>
                                <div className="mt-0.5 text-xs text-white/70 dark:text-slate-500">
                                    {user?.email}
                                </div>
                            </li>
                            <li className="my-1">
                                <hr className="dropdown-divider border-white/[0.08]" />
                            </li>
                            <li>
                                <NavLink
                                    to="/logout"
                                    className="dropdown-item hover:bg-white/5 flex items-center py-1.5 px-1 rounded !bg-transparent"
                                >
                                    <LogoutSvg />
                                    {translate("Head", "Logout")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default Dropdown;
