import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import UtilsService, { formatAmount } from "../../../../Services/UtilsService";
import { usePdfExport } from "../../../../App/ExportPdfProvider";

const PrintHistorique = ({ transactions, dates, setIsFlex }: any) => {
    const { print, setTransaction, setDates } = usePdfExport();

    useEffect(() => {
        setTransaction(transactions);
        setDates(dates);
    }, [transactions, dates]);

    return (
        <button
            className="btn bg-transparent !border-none md:w-auto lg:w-auto xl:w-auto   !p-0"
            onClick={print}
        >
            <img
                src={UtilsService.getPulicImage("/dist/image/icon_pdf.png")}
                className="w-[75px] object-fill"
                alt=""
            />
        </button>
    );
};

export default PrintHistorique;
