import DataTablePageLength from "./DataTablePageLength";
import DataTablePaginate from "./DataTablePaginate";

const DataTablePagination = ({
    tempData,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    handlePaginationChange,
}: {
    tempData: any[];
    currentPage: number;
    setCurrentPage: (page: number) => void;
    perPage: number;
    setPerPage: (page: number) => void;
    handlePaginationChange?: () => void;
}) => {
    return (
        <div className="intro-y col-span-12 flex flex-wrap items-center justify-end sm:flex-row sm:flex-nowrap">
            <DataTablePaginate
                perPage={perPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                tempData={tempData}
                handlePaginationChange={handlePaginationChange}
            />
            {/* <DataTablePageLength perPage={perPage} setPerPage={setPerPage} /> */}
        </div>
    );
};

export default DataTablePagination;
