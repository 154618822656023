import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AppMode {
    DARK = "dark",
    LIGHT = "light",
}

export interface AppTheme {
    mode: AppMode;
    oldMode: AppMode;
}

const localMode = localStorage.getItem("AppMode") as AppMode;
const mode = localMode ? localMode : AppMode.LIGHT;
const oldMode = mode === AppMode.DARK ? AppMode.LIGHT : AppMode.DARK;

document.documentElement.classList.remove(oldMode);
document.documentElement.classList.add(mode);

const initialState: AppTheme = {
    mode: mode,
    oldMode: oldMode,
};

const Theme = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ChangeAppMode(state, action: PayloadAction<{ mode: AppMode }>) {
            state.oldMode = state.mode;
            state.mode = action.payload.mode;
            localStorage.setItem("AppMode", state.mode);
            document.documentElement.classList.remove(state.oldMode);
            document.documentElement.classList.add(action.payload.mode);
        },
    },
});

export const { ChangeAppMode } = Theme.actions;

export default Theme.reducer;
