import { useState } from "react";
import FilterAZsvg from "../Svg/FilterAZsvg";
import FilterZAsvg from "../Svg/FilterZAsvg";

export enum FilterDirection {
    DESC = "DESC",
    ASC = "ASC",
}

interface SwitchProps {
    value: boolean;
    changeValue?: (state: boolean) => void;
}

const Filter = ({ value, changeValue }: SwitchProps) => {
    return (
        <div className="flex">
            <button
                type="button"
                className="border-none bg-transparent hover:border-none hover:bg-transparent"
                onClick={() => {
                    if (changeValue) {
                        changeValue(!value);
                    }
                }}
            >
                {!value ? <FilterAZsvg /> : <FilterZAsvg />}
            </button>
        </div>
    );
};

export const KpFilter = ({
    direction,
    isActual,
    changeValue,
}: {
    direction: FilterDirection;
    isActual?: boolean;
    changeValue: (direction: FilterDirection) => void;
}) => {
    return (
        <div className="flex">
            <button
                type="button"
                className={`border-none bg-transparent hover:border-none hover:bg-transparent ${
                    isActual ? "!text-kalypay-500" : ""
                } `}
                onClick={() => {
                    if (changeValue) {
                        changeValue(
                            direction === FilterDirection.ASC
                                ? FilterDirection.DESC
                                : FilterDirection.ASC,
                        );
                    }
                }}
            >
                {direction === FilterDirection.ASC ? (
                    <FilterAZsvg />
                ) : (
                    <FilterZAsvg />
                )}
            </button>
        </div>
    );
};

export default Filter;

export const FilterColumn = ({ value, changeValue }: SwitchProps) => {
    const [direction, setDirection] = useState(value);
    return (
        <div className="flex">
            <button
                type="button"
                className="border-none bg-transparent hover:border-none hover:bg-transparent"
                onClick={() => {
                    if (changeValue) {
                        changeValue(!direction);
                        setDirection(!direction);
                    }
                }}
            >
                {!direction ? <FilterAZsvg /> : <FilterZAsvg />}
            </button>
        </div>
    );
};
