import { UserType } from "./User";

export default class ICSVUser {
    uid: string;
    type?: UserType;
    name: string;
    email: string;
    is_active?: string;
    kyc?: string;
    constructor(uid: string, name: string, email: string) {
        this.uid = uid;
        this.name = name;
        this.email = email;
    }
}
