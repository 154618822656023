import React, { createContext, useState, useContext } from "react";

interface AdminUserContextData {
    adminUserId: string | null;
    setAdminUserId: (id: string | null) => void;
}

const AdminUserContext = createContext<AdminUserContextData | undefined>(
    undefined,
);

interface AdminUserProviderProps {
    children: React.ReactNode;
}

const AdminUserProvider: React.FC<AdminUserProviderProps> = ({ children }) => {
    const [adminUserId, setAdminUserId] = useState<string | null>(null);

    return (
        <AdminUserContext.Provider value={{ adminUserId, setAdminUserId }}>
            {children}
        </AdminUserContext.Provider>
    );
};

const useAdminUserContext = (): AdminUserContextData => {
    const context = useContext(AdminUserContext);

    if (!context) {
        throw new Error(
            "useAdminUserContext must be used within an AdminUserProvider",
        );
    }

    return context;
};

export { AdminUserProvider, useAdminUserContext };
export type { AdminUserContextData };
