import useLanguage from "../../../Services/Hooks/useLanguage";

export default function translator() {
    const { translations, defaultTranslations } = useLanguage();

    const replaceParams = (
        params: { [key: string]: string },
        text: string,
    ): string => {
        const keys = Object.keys(params);
        let return_text = text;
        for (const key of keys) {
            return_text = return_text.replace(
                new RegExp(`{{${key}}}`, "g"),
                params[key],
            );
        }

        return return_text;
    };

    const translate = (
        domain: string,
        text: string,
        params?: { [key: string]: string },
    ): string => {
        let dom = domain;
        let txt = text;

        if (domain === ".") {
            const splited = text.split(".");
            if (splited.length === 2) {
                dom = splited[0];
                txt = splited[1];
            }
        }
        const translated =
            translations[dom] && translations[dom][txt]
                ? translations[dom][txt]
                : defaultTranslations[dom] && defaultTranslations[dom][txt]
                ? defaultTranslations[dom][txt]
                : text;

        if (params) return replaceParams(params, translated);

        return translated;
    };

    return {
        translate,
    };
}
