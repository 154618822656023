import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { useLocation } from "react-router-dom";

interface ContextProps {
    sidebarOpen: boolean;
    processing: boolean;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
    setProcessing: Dispatch<SetStateAction<boolean>>;
    finalizeProcessing: () => void;
}

const AppContext = createContext<ContextProps>({
    sidebarOpen: false,
    setSidebarOpen: (): boolean => false,
    processing: false,
    setProcessing: (): boolean => false,
    finalizeProcessing: () => {
        return;
    },
});

export default function AppProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const finalizeProcessing = () => {
        setTimeout(() => {
            setProcessing(false);
        }, 1000);
    };

    const location = useLocation();

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    return (
        <AppContext.Provider
            value={{
                sidebarOpen,
                setSidebarOpen,
                processing,
                setProcessing,
                finalizeProcessing,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export const useAppProvider = () => useContext(AppContext);
