import { useRoutes } from "react-router-dom";

import AccountRoutes from "./AccountRoutes";
import AdminRoutes from "./AdminRoutes";
import KalypayRoutes from "../Front/Routes/Routes";
import LandingRoutes from "../Landing/routes/LandingRoutes";

const AppRoutes = () => {
    const routes = useRoutes([
        ...LandingRoutes,
        ...AccountRoutes,
        ...AdminRoutes,
        ...KalypayRoutes,
    ]);

    return <>{routes}</>;
};

export default AppRoutes;
