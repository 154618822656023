import Ecosystem, { IEcosystem } from "../../Models/Ecosystem";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): Ecosystem => {
    const notice = new Ecosystem(item.title, item.description);

    notice.rank = item.rank;
    notice.description = item.description;
    notice.created_at = item.created_at;
    notice.updated_at = item.updated_at;
    notice.photo = UtilsService.getPhotoUrl(item.photo);
    notice.id = item.id;
    notice.link = item.link;
    return notice;
};

const createFormData = (payload: IEcosystem): FormData => {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("link", payload.link);
    if (payload.photo) {
        formData.append("photo", payload.photo);
    }

    return formData;
};

const getAll = async (): Promise<Array<Ecosystem>> => {
    return axios
        .get(`/ecosystem`)
        .then(({ data }) => {
            const assets: Ecosystem[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const orderList = async (payload: string) => {
    try {
        const result = await axios.put(`/ecosystem/order-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getById = async (id: number): Promise<Ecosystem> => {
    return axios
        .get(`/ecosystem/${id}`)
        .then(({ data }) => {
            const asset: Ecosystem = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFirstEcosystem = async (): Promise<Ecosystem> => {
    return axios
        .get(`/ecosystem/get-first`)
        .then(({ data }) => {
            const asset: Ecosystem = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (support_id?: number) => {
    return axios
        .delete(`/ecosystem/${support_id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IEcosystem): Promise<Ecosystem | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/ecosystem", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Information créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    notice_id: number,
    payload: IEcosystem,
): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/ecosystem/${notice_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const EcosystemService = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    orderList,
    getFirstEcosystem,
};

export default EcosystemService;
