import { useEffect, useState } from "react";
import { DatatableFilter } from "../../../../Models/DatatableFilter";
import Transaction from "../../../../Models/Transaction";
import TransactionService from "../../../../Services/Wallets/TransactionService";
import PrintHistorique from "./TransanctionHistorique";
interface PropsDates {
    dates: any;
}
const PrintToPDF = ({ dates }: PropsDates) => {
    const [transanctions, setTransanctions] = useState<Array<Transaction>>([]);
    const [isFlex, setIsFlex] = useState<boolean>(true);
    const filter: DatatableFilter = {
        page: 0,
        size: 0,
        search: "search",
    };

    if (dates.startDate) {
        filter.start_date = dates.startDate;
    }
    if (dates.endDate) {
        filter.end_date = dates.endDate;
    }
    const getTransactions = async () => {
        try {
            const res =
                await TransactionService.getUserTransactionsWithoutPaginate(
                    filter,
                );
            setTransanctions(res);
        } catch (err: any) {}
    };
    useEffect(() => {
        getTransactions();
    }, [dates.startDate, dates.endDate]);
    return (
        transanctions && (
            <PrintHistorique
                transactions={transanctions}
                dates={dates}
                setIsFlex={setIsFlex}
            />
        )
    );
};
export default PrintToPDF;
