import React, { useState } from "react";
import ColdWalletOrderInfo from "./ColdWalletOrderInfo";
import { ModalProvider } from "../../../Components/Extra/Modal/ModalContext";
import User from "../../../Models/User";

interface CooldWalletNBRInterface {
    price: number;
    status: boolean;
    user: User;
}
const CooldWalletNBR = ({ price, status, user }: CooldWalletNBRInterface) => {
    const [value, setValue] = useState<number>(0);
    const isButtonDisabled = value === 0;
    return (
        <div className="mt-5">
            <div className="flex justify-center items-center h-12">
                <button
                    className={
                        value === 0
                            ? "btn-dark hover:border-none border-none px-3 h-full !cursor-not-allowed"
                            : "btn-dark hover:border-none border-none px-3 h-full"
                    }
                    onClick={() => setValue(value - 1)}
                    disabled={isButtonDisabled}
                >
                    -
                </button>
                <input
                    type="text"
                    className="h-full w-28 text-center bg-transparent !border-slate-600"
                    disabled
                    value={value}
                    min="0"
                />
                <button
                    className="btn-dark hover:border-none border-none px-3 h-full"
                    onClick={() => setValue(value + 1)}
                >
                    +
                </button>
            </div>
            <div className="text-center my-4 text-kalypay-500 text-xl text-bold">
                $ {price * value}
            </div>
            {status && value > 0 && (
                <ModalProvider>
                    <ColdWalletOrderInfo quantity={value} user={user} />
                </ModalProvider>
            )}
        </div>
    );
};

export default CooldWalletNBR;
