import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DataTable, {
    DataTableColums,
} from "../../../../../Components/Extra/DataTable/DataTable";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import MerchantCategory from "../../../../../Models/MerchantCategory";
import MerchantCategoryService from "../../../../../Services/Roles/MerchantService/MerchantCategoryService";

const MerchantCategoryTable = () => {
    const navigate = useNavigate();
    const [processing, setProcessing] = useState<boolean>(false);
    const [categories, setCategories] = useState<Array<MerchantCategory>>([]);

    const getAll = async () => {
        try {
            const res = await MerchantCategoryService.getAll();
            setCategories(res);
        } catch (err: any) {}
    };

    const deleteById = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        elt: MerchantCategory,
    ) => {
        event.preventDefault();
        setProcessing(true);

        Swal.fire({
            title: "Suppression d'une categorie",
            text: `Voulez vous supprimer le ${elt.name} ?`,
            icon: "question",
            showCloseButton: true,
            confirmButtonColor: "#daa520",
            showCancelButton: true,
            cancelButtonText: "Annuler",
        }).then((res) => {
            if (res.isConfirmed) {
                try {
                    const promise = MerchantCategoryService.deleteById(elt.id);

                    alert_message
                        .promise("Suppression en cours ...", promise)
                        .finally(() => setProcessing(false));
                } catch (error) {}
            }
        });
    };

    const colums: DataTableColums[] = [
        {
            index: "name",
            title: "Nom",
            content: (row: MerchantCategory) => {
                return row.name;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "action",
            title: "Actions",
            content: (row: MerchantCategory) => {
                return (
                    <div className="flex items-center justify-center justify-end-mobile">
                        <NavLink
                            className="mr-3 flex items-center"
                            to={`edit/${row.id}`}
                            state={{ category: row }}
                        >
                            <i
                                data-lucide="check-square"
                                className="mr-1 h-4 w-4"
                            ></i>
                            Modifier
                        </NavLink>

                        <button
                            className="text-danger flex items-center"
                            type="button"
                            onClick={(event) => deleteById(event, row)}
                        >
                            <i
                                data-lucide="trash-2"
                                className="mr-1 h-4 w-4"
                            ></i>{" "}
                            Supprimer{" "}
                        </button>
                    </div>
                );
            },
            filter: false,
            className: "whitespace-nowrap text-center",
        },
    ];

    useEffect(() => {
        getAll();
    }, [processing]);

    return (
        <DataTable
            className="table-report -mt-2 table"
            columns={colums}
            data={categories}
            tableButton={
                <button
                    className="btn btn-perfect mr-2"
                    onClick={() => navigate("new")}
                >
                    Ajout nouveau
                </button>
            }
        />
    );
};

export default MerchantCategoryTable;
