import axios from "../AxiosInstance";

const checkRef = async (ref: string) => {
    try {
        const result = await axios.get(`/transaction/${ref}`);

        return result.data;
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getDetail = async (id: number) => {
    try {
        const result = await axios.get(`/transaction/${id}/details`);

        return result.data;
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const TransactionService = {
    getDetail,
    checkRef,
};

export default TransactionService;
