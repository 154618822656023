import { useCallback, useEffect, useState } from "react";
import { DataTableColums } from "../../../Components/Extra/DataTable/DataTable";
import translator from "../../../Components/Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import DataTableWithPaginatedData from "../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import UserService from "../../../Services/User/UserService";
import ModerationService from "../../../Services/Moderation/ModerationService";
import { ModalProvider } from "../../../Components/Extra/Modal/ModalContext";
import ButtonViewModalModeraton from "./ButtonViewModalModeraton";
import Moderation, { StatusModeration } from "../../../Models/Moderation";
import async from "react-select/dist/declarations/src/async/index";
import KpButton from "../../Components/Inputs/KpButton";
import Swal from "sweetalert2";
import alert_message from "../../../Services/Alert/AlertMessage";
import { useAppProvider } from "../../Provider/AppProvider";

const ModerationList = ({ status }: { status: string }) => {
    const { translate } = translator();
    const { processing, setProcessing, finalizeProcessing } = useAppProvider();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [incrementor, setIncrementor] = useState<number>(0);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [filterIndex, setFilterIndex] = useState<string>();
    const [filterProcess, setFilterProcess] = useState<boolean>(false);
    const [moderations, setModerations] = useState<Array<Moderation>>([]);

    const changeStatus = async (mod: Moderation, status: StatusModeration) => {
        Swal.fire({
            title: "Confirmation",
            icon: "question",
            text: `Vous êtes sûr de vouloir ${
                status === StatusModeration.ACCEPTED ? "accepter" : "refuser"
            } cette demande ?`,
            showCancelButton: true,
        }).then(async (value: any) => {
            if (value.isConfirmed) {
                setProcessing(true);
                try {
                    const data = {
                        id: mod.id,
                        status: status,
                    };
                    const action = ModerationService.changeStatus(data);

                    await alert_message.promise(
                        "En cours ...",
                        action,
                        "Action fait avec succès",
                    );

                    if (currentPage !== 0) setCurrentPage(0);
                    else setIncrementor(incrementor + 1);
                } catch (err: any) {
                } finally {
                    finalizeProcessing();
                }
            }
        });
    };

    const colums: DataTableColums[] = [
        {
            index: "created_at",
            title: "Date",
            content: (row: Moderation) => {
                return row.created_at
                    ? UtilsService.getBeautifulFormatedDate(row.created_at)
                    : "";
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.created_at.filter,
        },
        {
            index: "uid",
            title: "UID",
            content: (row: Moderation) => {
                return row.user.uid;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.uid.filter,
        },
        {
            index: "name",
            title: "Admin_Users.Name",
            content: (row: Moderation) => {
                return row.user.first_name + " " + row.user.last_name;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.name.filter,
        },
        {
            index: "type",
            title: "Type",
            content: (row: Moderation) => {
                return (
                    <> {translate("AdressTypeModeraton", row.type || "")} </>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.type.filter,
        },
        {
            index: "status",
            title: "Status",
            content: (row: Moderation) => {
                return <> {translate("STATUS", row.status || "")} </>;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.email.filter,
        },
        {
            index: "action",
            title: "Action",
            content: (row: Moderation) => {
                return (
                    <div className="flex flex-row space-x-1">
                        <ModalProvider>
                            <ButtonViewModalModeraton moderation={row} />
                        </ModalProvider>
                        {row.status == StatusModeration.PENDING ? (
                            <div className="space-x-1">
                                <KpButton
                                    mode="normal"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusModeration.ACCEPTED,
                                        )
                                    }
                                >
                                    Valider
                                </KpButton>
                                <KpButton
                                    mode="danger"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusModeration.DECLINED,
                                        )
                                    }
                                >
                                    Refuser
                                </KpButton>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.email.filter,
        },
    ];
    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);

    const getAllModerations = async () => {
        setProcessing(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        filter.status = status;

        try {
            const res = await ModerationService.getAllFilter(filter);
            setModerations([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            finalizeProcessing();
        }
    };
    useEffect(() => {
        setIncrementor(incrementor + 1);
    }, [perPage, search, filterIndex, filterProcess, status]);

    useEffect(() => {
        if (incrementor > 1) setIncrementor(incrementor + 1);
    }, [currentPage]);

    useEffect(() => {
        getAllModerations();
    }, [incrementor]);

    return (
        <>
            <DataTableWithPaginatedData
                className="table-report -mt-2 table"
                columns={colums}
                data={moderations}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                // exportUserCSV={["PN74212611", "PN05216451"].includes(
                //     user?.uid || "",
                // )}
                // tableHeaderFilter={headerFilter}
                // handleChangeFilter={handleChangeFilter}
            />
        </>
    );
};

export default ModerationList;
