import BreadCrumbV2, {
    Ariane,
} from "../../../../../Layouts/Account/BreadCrumb/BreadCrumbV2";

import MerchantCategoryTable from "./MerchantCategoryTable";

const Arianes: Ariane[] = [
    {
        path: "#",
        name: "Admin",
    },
    {
        path: "/admin/merchant-category",
        name: "Categorie",
    },
    {
        path: "/admin/merchant-category",
        name: "Liste",
    },
];

const ListMerchantCategory = () => {
    // const navigate = useNavigate();

    return (
        <div>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-5 font-medium text-lg  bg-white p-2 rounded">
                Liste de tous les categories
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <MerchantCategoryTable />
            </div>
        </div>
    );
};

export default ListMerchantCategory;
