import { LangType } from "./Lang";

export interface INotice {
    title: string;
    description: string;
    status: boolean;
    photo?: File;
    is_external_link?: boolean;
    title_en?: string;
    description_en?: string;
    title_es?: string;
    description_es?: string;
    lang: LangType;
}

export default class Notice {
    id?: number;
    rank!: number;
    title!: string;
    description!: string;
    status!: boolean;
    photo?: string;
    created_at?: Date;
    updated_at?: Date;
    is_external_link?: boolean;
    title_en?: string;
    description_en!: string;
    title_es!: string;
    description_es!: string;
    lang!: LangType;

    constructor(
        lang: LangType,
        title: string,
        description: string,
        status: boolean,
    ) {
        this.title = title;
        this.description = description;
        this.status = status;
        this.lang = lang;
    }
}
