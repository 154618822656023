import UtilsService from "../../Services/UtilsService";

interface FileInputProps {
    file?: File;
    defaultUrl?: string;
    setFile: (file: File) => void;
}

const FileInput = ({ file, defaultUrl, setFile }: FileInputProps) => {
    const handleChangeImage = () => {
        const pics = document.getElementById("file-input");
        pics?.click();
    };

    return (
        <div className="image-fit relative h-20 w-20 flex-none sm:h-24 sm:w-24 lg:h-32 lg:w-32">
            <div>
                <input
                    className="hidden"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="file-input"
                    aria-label="Browse"
                    onChange={(e) => {
                        if (e.target.files) {
                            setFile(e.target.files[0]);
                        }
                    }}
                />
                <img
                    alt="image"
                    className="rounded-full  w-52 h-32 md-img object-cover"
                    src={
                        file
                            ? URL.createObjectURL(file)
                            : defaultUrl
                            ? defaultUrl
                            : UtilsService.getPulicImage(
                                  "/dist/image/profile-5.jpg",
                              )
                    }
                    onClick={handleChangeImage}
                />
            </div>
            <button
                type="button"
                className="btn-dark absolute bottom-0 right-0 mb-1 mr-1 flex items-center justify-center rounded-full p-2"
                onClick={handleChangeImage}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="camera"
                    data-lucide="camera"
                    className="lucide lucide-camera block mx-auto text-white"
                >
                    <path d="M14.5 4h-5L7 7H4a2 2 0 00-2 2v9a2 2 0 002 2h16a2 2 0 002-2V9a2 2 0 00-2-2h-3l-2.5-3z"></path>
                    <circle cx="12" cy="13" r="3"></circle>
                </svg>
            </button>
        </div>
    );
};

export default FileInput;
