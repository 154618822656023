import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import Maintenance from "../Landing/pages/Maintenace/Maintenance";
import useAuthentication from "../Services/Authentication/useAuthentication";
import RoutesNames from "../Services/RoutesNames";

interface MaintenanceContextProps {
    maintenance: "global" | "partial" | "none";
}

const MaintenanceContext = createContext<MaintenanceContextProps | undefined>(
    undefined,
);

export const MaintenanceProvider = ({
    children,
    initialState = (process.env.REACT_APP_MAINTENANCE_MODE as any) || "none",
}: {
    children: React.ReactNode;
    initialState?: "global" | "partial" | "none";
}) => {
    const [maintenance, setMaintenance] = useState<
        "global" | "partial" | "none"
    >(initialState);

    const { isAdmin } = useAuthentication();

    const location = useLocation();
    let show_maintenance = false;
    if (!isAdmin) {
        if (
            maintenance === "global" &&
            ![RoutesNames.SilentLogin].includes(location.pathname)
        )
            show_maintenance = true;
        if (
            maintenance === "partial" &&
            [RoutesNames.Login, RoutesNames.Register].includes(
                location.pathname,
            )
        )
            show_maintenance = true;
    }

    return (
        <MaintenanceContext.Provider value={{ maintenance }}>
            {show_maintenance ? (
                <>
                    <Maintenance></Maintenance>
                </>
            ) : (
                <>{children}</>
            )}
        </MaintenanceContext.Provider>
    );
};

export const useMaintenanceContext = () => {
    const context = useContext(MaintenanceContext);
    if (!context) {
        throw new Error(
            "useMaintenance must be used within a MaintenanceProvider",
        );
    }
    return context;
};
