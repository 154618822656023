export default class BonusChartData {
    roi_stacking: number;
    roi_knt: number;
    rank_bonus: number;
    pool_bonus: number;
    affiliation: number;

    constructor(
        roi_stacking: number,
        roi_knt: number,
        rank_bonus: number,
        pool_bonus: number,
        affiliation: number,
    ) {
        this.roi_stacking = roi_stacking;
        this.roi_knt = roi_knt;
        this.rank_bonus = rank_bonus;
        this.pool_bonus = pool_bonus;
        this.affiliation = affiliation;
    }
}
