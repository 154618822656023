import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbV2, {
    Ariane,
} from "../../../Layouts/Account/BreadCrumb/BreadCrumbV2";
// import RankForm from "./RankForm";
import translator from "../../../Components/Extra/Translation/Translate";
import EcosystemForm from "./EcosystemForm";
// Add_A_New_Level

const EcosystemNew = () => {
    const { translate } = translator();
    const navigate = useNavigate();
    const { id } = useParams();

    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/ecosystem",
            name: "Ecosystem",
        },
        {
            path: "",
            name: id ? "Edit" : "Add",
        },
    ];

    return (
        <div>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-10 bg-white p-2 rounded text-lg font-medium">
                {id
                    ? translate("Admin_Notice", "Edit")
                    : translate("Admin_Notice", "Add")}
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    <button
                        className="btn btn-perfect mr-2"
                        onClick={() => navigate("/admin/ecosystem")}
                    >
                        {translate("Admin_Notice", "List")}
                    </button>
                </div>
                <div className="intro-y col-span-12 lg:col-span-12">
                    <EcosystemForm id={id ? Number(id) : undefined} />
                </div>
            </div>
        </div>
    );
};

export default EcosystemNew;
