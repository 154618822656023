import SaveSvg from "../Svg/SaveSvg";

interface SaveBoutonProps {
    htmlType: "submit" | "button" | "reset";
    onClick?: () => void;
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    disabled?: boolean;
}
const SaveBouton = ({
    htmlType,
    className,
    children,
    style,
    disabled,
    onClick,
}: SaveBoutonProps) => {
    return (
        <button
            type={htmlType}
            onClick={onClick}
            className={`btn btn-perfect mb-2 w-32 ${className}`}
            style={style}
            disabled={!!disabled}
        >
            <SaveSvg /> {children}
        </button>
    );
};

export default SaveBouton;
