export enum CARD_TYPE {
    RED = "red",
    GOLD = "gold",
    BLACK = "black",
}

export enum USER_CARD_STATUS {
    PREACTIVE = "preactive",
    ACTIVE = "active",
    DESACTIVE = "desactive",
    BLOQUED = "bloqued",
}

export interface ICardRequestPayload {
    last_4_digits: string;
    transaction_password: string;
}

export interface CardTransactionPayload {
    last_4_digits: string;
    transaction_password: string;
    wallet: string;
    amount: number;
}
export default class UserCard {
    card_type!: CARD_TYPE;
    status!: USER_CARD_STATUS;
    last_4_digits!: string;
    last_balance!: number;
    last_name!: string;
    first_name!: string;

    constructor(
        card_type: CARD_TYPE,
        status: USER_CARD_STATUS,
        last_4_digits: string,
        last_balance: number,
        last_name: string,
        first_name: string,
    ) {
        this.card_type = card_type;
        this.status = status;
        this.last_4_digits = last_4_digits;
        this.last_balance = last_balance;
        this.last_name = first_name;
        this.first_name = last_name;
    }
}
