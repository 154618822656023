export enum StatusModeration {
    PENDING = "pending",
    DECLINED = "declined",
    ACCEPTED = "accepted",
}

export enum AdressTypeModeraton {
    PHONE = "phone",
    ADDRESS = "address",
}

export default class Moderation {
    id?: number;
    adress?: string;
    ville?: string;
    codepostal?: string;
    phone_country_code?: string;
    phone?: string;
    country?: string;
    old_country?: string;
    old_adress?: string;
    old_ville?: string;
    old_codepostal?: string;
    old_phone_country_code?: string;
    old_phone?: string;
    status?: StatusModeration;
    created_at?: Date;
    type?: AdressTypeModeraton;
    user?: any;
    currency?: string;
    constructor(
        id?: number,
        adress?: string,
        ville?: string,
        codepostal?: string,
        phone_country_code?: string,
        phone?: string,
        country?: string,
        old_country?: string,
        old_adress?: string,
        old_ville?: string,
        old_codepostal?: string,
        old_phone_country_code?: string,
        old_phone?: string,
        status?: StatusModeration,
        created_at?: Date,
        type?: AdressTypeModeraton,
        user?: any,
        currency?: string,
    ) {
        this.id = id;
        this.adress = adress;
        this.ville = ville;
        this.codepostal = codepostal;
        this.phone_country_code = phone_country_code;
        this.phone = phone;
        this.country = country;
        this.old_country = old_country;
        this.old_adress = old_adress;
        this.old_ville = old_ville;
        this.old_codepostal = old_codepostal;
        this.old_phone_country_code = old_phone_country_code;
        this.old_phone = old_phone;
        this.status = status;
        this.created_at = created_at;
        this.type = type;
        this.user = user;
        this.currency = currency;
    }
}
