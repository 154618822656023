const DataTablePageLength = ({
    perPage,
    setPerPage,
}: {
    perPage: number;
    setPerPage: (page: number) => void;
}) => {
    const paginations = [1, 10, 25, 50, 100];
    return (
        <select
            className="form-select box mt-3 w-20 sm:mt-0"
            onChange={(e) => setPerPage(Number(e.target.value))}
            defaultValue={perPage}
        >
            {paginations.map((length) => {
                return (
                    <option key={`pl-${length}`} value={length}>
                        {length}
                    </option>
                );
            })}
        </select>
    );
};

export default DataTablePageLength;
