import { useMemo } from "react";
import { useAppProvider } from "../../Provider/AppProvider";

interface KpButtonProps {
    label?: string;
    onClick?: (e: any) => void;
    className?: string;
    disabled?: boolean;
    disabledProcessing?: boolean;
    type?: "submit" | "button";
    children?: React.ReactNode;
    mode?: "default" | "danger" | "normal" | "purple" | "reset";
}

const KpButton = ({
    label,
    onClick,
    className = "",
    disabled = false,
    disabledProcessing = true,
    type = "submit",
    children,
    mode = "default",
}: KpButtonProps) => {
    const { processing } = useAppProvider();

    const defaultClass = useMemo(() => {
        switch (mode) {
            case "danger":
                return "bg-[#A91C2A] hover:bg-[#9B1B27] text-white font-bold py-2 px-4 rounded-full";

            case "normal":
                return "bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full";

            case "purple":
                return " bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full";
            case "reset":
                return "btn bg-red-600 text-white";
            default:
                return "btn btn-perfect ";
        }
    }, [mode]);

    return (
        <button
            type={type}
            className={`${defaultClass} ${className}`}
            disabled={disabled || (disabledProcessing && processing)}
            onClick={onClick}
        >
            {children}
            {label && <>{label}</>}
        </button>
    );
};

export default KpButton;
