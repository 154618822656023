export interface AgentAdvantage {
    free_cb: OrderCardRequestType;
    fiat_feed: string;
    min_klc: number;
    red_fiat_load_fee: number;
    red_crypto_purchase_fee: number;
    red_crypto_sell_fee: number;
    red_active_swap_fee: number;
    red_external_transfer_fiat_fee: number;
    red_merchand_payment_fee: number;
    cashback_klc_payment_card: number;
    card_atm_daily_free_withdraw: number;
    card_atm_monthly_free_withdraw: number;
    monthly_free_payment_by_card: number;
    roi_boost_earning: number;
    fiat_cm_reload_com: number;
    fiat_rm_reload_com: number;
    fiat_agent_reload_com: number;
    fiat_cm_withdraw_com: number;
    fiat_rm_withdraw_com: number;
    fiat_agent_withdraw_com: number;
    reload_direct_aff_fiat_com: number;
    direct_active_aff_swap_com: number;
    external_fiat_transfer_direct_aff_com: number;
    red_crypto_interest_rate: number;
    obt_iban_euro: number;
    obt_iban_pound: number;
    obt_iban_dollar: number;
}

export enum OrderCardRequestType {
    RED = "red",
    GOLD = "gold",
    BLACK = "black",
}

export enum SettingKey {
    AgentAdvantage = "agent_advantage",
    RegionalManager = "regional_manager",
    CountryManager = "country_advantage",
}

export enum EAdvantage {
    MIN_KLC = "min_klc",
    FREE_CB = "free_cb",
    FIAT_FEED = "fiat_feed",
    RED_FIAT_LOAD_FEE = "red_fiat_load_fee",
    RED_CRYPTO_PURCHASE_FEE = "red_crypto_purchase_fee",
    RED_CRYPTO_SELL_FEE = "red_crypto_sell_fee",
    RED_ACTIVE_SWAP_FEE = "red_active_swap_fee",
    RED_EXTERNAL_TRANSFER_FIAT_FEE = "red_external_transfer_fiat_fee",
    RED_MERCHAND_PAYMENT_FEE = "red_merchand_payment_fee",
    CASHBACK_KLC_PAYMENT_CARD = "cashback_klc_payment_card",
    CARD_ATM_DAILY_FREE_WITHDRAW = "card_atm_daily_free_withdraw",
    CARD_ATM_MONTHLY_FREE_WITHDRAW = "card_atm_monthly_free_withdraw",
    MONTHLY_FREE_PAYMENT_BY_CARD = "monthly_free_payment_by_card",
    ROI_BOOST_EARNING = "roi_boost_earning",
    FIAT_CM_RELOAD_COM = "fiat_cm_reload_com",
    FIAT_RM_RELOAD_COM = "fiat_rm_reload_com",
    FIAT_AGENT_RELOAD_COM = "fiat_agent_reload_com",
    FIAT_CM_WITHDRAW_COM = "fiat_cm_withdraw_com",
    FIAT_RM_WITHDRAW_COM = "fiat_rm_withdraw_com",
    FIAT_AGENT_WITHDRAW_COM = "fiat_agent_withdraw_com",
    RELOAD_DIRECT_AFF_FIAT_COM = "reload_direct_aff_fiat_com",
    DIRECT_ACTIVE_AFF_SWAP_COM = "direct_active_aff_swap_com",
    EXTERNAL_FIAT_TRANSFER_DIRECT_AFF_COM = "external_fiat_transfer_direct_aff_com",
    RED_CRYPTO_INTEREST_RATE = "red_crypto_interest_rate",
    OBT_IBAN_EURO = "obt_iban_euro",
    OBT_IBAN_POUND = "obt_iban_pound",
    OBT_IBAN_DOLLAR = "obt_iban_dollar",
}
