import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SaveBouton from "../../../../../Components/Extra/Bouton/SaveBouton";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import { IMerchantCategory } from "../../../../../Models/MerchantCategory";
import MerchantCategoryService from "../../../../../Services/Roles/MerchantService/MerchantCategoryService";

const MerchantCategoryForm = ({ id }: { id?: number }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { category } = location.state || {};

    const [name, setName] = useState<string>(category?.name);

    const create = (categ: IMerchantCategory) => {
        const create = MerchantCategoryService.create(categ);
        alert_message
            .promise("Création de la categorie encours ...", create)
            .then(() => {
                navigate("/admin/merchant-category");
            });
    };

    const update = (id: number, categ: IMerchantCategory) => {
        const update = MerchantCategoryService.update(categ, id);
        alert_message.promise("Mise à jour encours ...", update).then(() => {
            navigate("/admin/merchant-category");
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const categ: IMerchantCategory = {
            name: name,
        };

        if (id) {
            update(id, categ);
        } else {
            create(categ);
        }
    };

    const getCategory = async () => {
        if (id && !category) {
            try {
                const res = await MerchantCategoryService.getById(Number(id));
                setName(res.name);
            } catch (err: any) {
                alert_message.error(err);
                setTimeout(() => navigate("/admin/merchant-category/"), 3000);
            }
        }
    };

    useEffect(() => {
        getCategory();
    }, [id]);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-12 gap-12">
                <div className="col-span-12 lg:col-span-8 2xl:col-span-12">
                    <div className="intro-y box">
                        <div className="p-5">
                            <div className="grid md:grid-cols-2 md:gap-12">
                                <div className="relative z-0 w-full group">
                                    <input
                                        type="text"
                                        id="categ-name"
                                        className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        placeholder=" "
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        required
                                    />
                                    <label
                                        htmlFor="categ-name"
                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                    >
                                        Nom
                                    </label>
                                </div>
                            </div>
                            <div className="mt-5 text-right">
                                <SaveBouton htmlType="submit">
                                    {id ? "Modifier" : "Enregistrer"}{" "}
                                </SaveBouton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default MerchantCategoryForm;
