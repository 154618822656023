import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { ItemTypes } from "../../../Layout/DragDrop/ItemTypes";
import { NavLink } from "react-router-dom";
import alert_message from "../../../../Services/Alert/AlertMessage";
import translator from "../../../../Components/Extra/Translation/Translate";
import Swal from "sweetalert2";
import Ecosystem from "../../../../Models/Ecosystem";
import EcosystemService from "../../../../Services/EcosystemService/EcosystemService";

export interface CardProps {
    id: any;
    index: number;
    ecosystem: Ecosystem;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    getDraged: (rank: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const Card: React.FC<CardProps> = ({
    id,
    index,
    ecosystem,
    moveCard,
    getDraged,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { translate } = translator();
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    const deleteById = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        elt: Ecosystem,
    ) => {
        event.preventDefault();
        Swal.fire({
            title: translate("Admin_Affiliation", "Deleting_A_Unilevel"),
            text: `${translate(
                "Admin_Affiliation",
                "Do_You_Want_To_Delete_The",
            )} ${elt.title} ?`,
            icon: "question",
            showCloseButton: true,
            confirmButtonColor: "#daa520",
            showCancelButton: true,
            cancelButtonText: translate("Admin_Affiliation", "Cancel"),
        }).then((res) => {
            if (res.isConfirmed) {
                try {
                    const promise = EcosystemService.deleteById(elt.id);

                    alert_message.promise(
                        translate("Admin_Affiliation", "Deletion_In_Progress"),
                        promise,
                    );
                } catch (error) {}
            }
        });
    };

    return (
        <div
            ref={ref}
            style={{ opacity }}
            data-handler-id={handlerId}
            className="box shadow rounded px-4 py-3 mb-2 cursor-move"
            onDragEnd={() => getDraged(ecosystem.rank)}
        >
            <div className="flex w-full">
                <div className="flex items-center justify-center w-[20%]">
                    <div className="image-fit zoom-in h-10 w-10">
                        {ecosystem.photo && (
                            <img
                                alt="user pics"
                                className="tooltip rounded-full"
                                src={ecosystem.photo}
                            />
                        )}
                    </div>
                </div>
                <div className="mr-5 w-[20%] text-center justify-center align-middle">
                    <span>{ecosystem.title}</span>
                </div>
                <div className="mr-5 w-[30%] text-center justify-center">
                    {ecosystem.link}
                </div>
                <div className="flex items-center justify-center w-[30%]">
                    <NavLink
                        className="mr-3 flex items-center"
                        to={`edit/${ecosystem.id}`}
                        state={{ asset: ecosystem }}
                    >
                        <i
                            data-lucide="check-square"
                            className="mr-1 h-4 w-4"
                        ></i>
                        {translate("Admin_Affiliation", "Edit")}
                    </NavLink>

                    <button
                        className="text-danger flex items-center"
                        type="button"
                        onClick={(event) => deleteById(event, ecosystem)}
                    >
                        <i data-lucide="trash-2" className="mr-1 h-4 w-4"></i>{" "}
                        {translate("Admin_Affiliation", "Delete")}{" "}
                    </button>
                </div>
            </div>{" "}
        </div>
    );
};

export default Card;
