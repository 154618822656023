import CopySvg from "../../Components/Extra/Svg/CopySvg";
import translator from "../../Components/Extra/Translation/Translate";
import alert_message from "../../Services/Alert/AlertMessage";

const KpCopyClick = ({ text, message }: { text: string; message: string }) => {
    const { translate } = translator();
    return (
        <button
            className=" bg-transparent"
            type="button"
            onClick={() => {
                navigator.clipboard.writeText(text);
                alert_message.success(
                    message != ""
                        ? message
                        : translate("INFORMATIONS", "TEXT_COPIED"),
                );
            }}
        >
            <CopySvg />
        </button>
    );
};

export default KpCopyClick;
