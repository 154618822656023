import Kyc from "../../Models/Kyc";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const mapKyc = (elt: any): Kyc => {
    const kyc = new Kyc(elt.session_id, elt.type, elt.status);

    kyc.info = elt.info;
    kyc.steps = elt.steps;
    kyc.id = elt.id;

    return kyc;
};

const getByUser = async (): Promise<Kyc> => {
    return axios
        .get(`/kyc/session`)
        .then(({ data }) => {
            const res: Kyc = mapKyc(data);
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const refresh = async () => {
    return axios.get(`/kyc/refresh`);
};

const KycService = {
    getByUser,
    refresh,
};

export default KycService;
