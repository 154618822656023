import ColdWalletOrder, {
    IColdWalletOrderPayload,
} from "../../Models/ColdWalletOrder";
import { DatatableFilter } from "../../Models/DatatableFilter";
import Option from "../../Models/Select";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any, countries_list_option: Option[]): ColdWalletOrder => {
    const cold_wallet_order = new ColdWalletOrder(
        item.id,
        item.quantity,
        item.country,
        item.city,
        item.postal_code,
        item.address,
        item.unit_price,
        item.user,
        item.status,
        item.phone_number,
    );
    const find_country = countries_list_option.find(
        (elt: Option) => elt.value === item.country,
    );
    const country = find_country?.label ?? "";

    cold_wallet_order.id = item.id;
    cold_wallet_order.quantity = item.quantity;
    cold_wallet_order.country = country;
    cold_wallet_order.city = item.city;
    cold_wallet_order.postal_code = item.postal_code;
    cold_wallet_order.address = item.address;
    cold_wallet_order.unit_price = item.unit_price;
    cold_wallet_order.user = item.user;
    cold_wallet_order.status = item.status;
    cold_wallet_order.created_at = item.created_at;
    cold_wallet_order.updated_at = item.updated_at;
    cold_wallet_order.id = item.id;

    return cold_wallet_order;
};

const getAll = async (): Promise<Array<ColdWalletOrder>> => {
    const countries_list = await getCountryListOption();
    return axios
        .get(`/cold-wallet-order`)
        .then(({ data }) => {
            const assets: ColdWalletOrder[] = data.map((item: any) =>
                map(item, countries_list),
            );
            console.log(assets);
            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<ColdWalletOrder> => {
    const countries_list = await getCountryListOption();
    return axios
        .get(`/cold-wallet-order/${id}`)
        .then(({ data }) => {
            const asset: ColdWalletOrder = map(data, countries_list);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCountryList = async (): Promise<any> => {
    const response = await axios.get("https://restcountries.com/v3.1/all");
    return response.data;
};

const cancelOrderById = async (order_id: number) => {
    return axios
        .put(`/cold-wallet-order/cancel-order/${order_id}`)
        .then(() => {
            return Promise.resolve("Annulation de la commande avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const completOrderById = async (order_id: number) => {
    return axios
        .put(`/cold-wallet-order/complet-order/${order_id}`)
        .then(() => {
            return Promise.resolve("Achèvement de la commande avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IColdWalletOrderPayload): Promise<string> => {
    return axios
        .post("/cold-wallet-order", payload)
        .then(() => {
            return Promise.resolve("Commande créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllColdWalletOrderRequest = async (
    filter: DatatableFilter,
): Promise<any> => {
    const countries_list = await getCountryListOption();

    const find_country = countries_list.find(
        (elt: Option) => elt.label === filter.search,
    );
    filter.search = find_country ? find_country.value : filter.search;
    return axios
        .get(`/cold-wallet-order/get-all-cold-wallet-order-request`, {
            params: filter,
        })
        .then(({ data }) => {
            const assets: ColdWalletOrder[] = data.data.map((item: any) =>
                map(item, countries_list),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCountryListOption = async () => {
    const country_list = await getCountryList();
    const assets: Option[] = country_list.map((country: any) =>
        mapCountry(country),
    );
    return assets;
};

const mapCountry = (item: any): Option => {
    const option = new Option(item.name.official, item.cca3, item.flags.png);

    option.label = item.name.official;
    option.value = item.cca3;
    option.image = item.flags.png;

    return option;
};

const ColdWalletOrderService = {
    getAll,
    getById,
    create,
    cancelOrderById,
    completOrderById,
    getCountryList,
    getAllColdWalletOrderRequest,
    getCountryListOption,
};

export default ColdWalletOrderService;
