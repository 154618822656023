import React, { useState } from "react";
import { ModalContext } from "../../../../../Components/Extra/Modal/ModalContext";
import translator from "../../../../../Components/Extra/Translation/Translate";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import SaveBouton from "../../../../../Components/Extra/Bouton/SaveBouton";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import UserService from "../../../../../Services/User/UserService";
import { IAddAffiliation } from "../../../../../Models/User";
import { useAdminUserContext } from "../AdminUserProvider";
import SelectInputUser from "../../../../../Components/Extra/Input/SelectInputUser";

const UserAffiliation = () => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const [processing, setProcessing] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const { adminUserId } = useAdminUserContext();
    const [selectedUser, setSelectedUser] = useState<DOption>();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (selectedUser && adminUserId !== undefined && adminUserId !== null) {
            const payload: IAddAffiliation = {
                uid_to_add: selectedUser.value,
                uid_parrain: adminUserId,
            };

            setProcessing(true);
            const create = UserService.addAffiliate(payload);
            alert_message
                .promise("Modification en cours ...", create)
                .then(() => {
                    handleModal();
                    setProcessing(false);
                    setReload(!reload);
                });
        }
    };

    const handleChangeUser = (value: any) => {
        setSelectedUser(value);
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 lg:col-span-12 2xl:col-span-9">
                    <div className="intro-y">
                        <div className="p-3">
                            <div className="grid md:grid-cols-1 md:gap-12 mb-4">
                                <h1>
                                    {translate(
                                        "Admin_Affiliation",
                                        "Add_godchild",
                                    )}
                                </h1>
                            </div>
                            <div className="grid md:grid-cols-1 md:gap-12 mb-4">
                                <div className="relative z-0 w-full group">
                                    <SelectInputUser
                                        id="list-userds"
                                        onChange={(val: any) =>
                                            handleChangeUser(val.value)
                                        }
                                        no_parrain={true}
                                    />
                                    <label
                                        htmlFor="list-userds"
                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                    >
                                        {translate("Admin_Dashboard", "Users")}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end items md:right w-full">
                            <SaveBouton
                                htmlType="submit"
                                disabled={processing}
                                className="mr-2"
                            >
                                {translate("Admin_Users", "Validate")}
                            </SaveBouton>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const AffiliationModal = () => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <button
            type="button"
            className="btn btn-perfect"
            onClick={() => handleModal(<UserAffiliation />)}
        >
            {translate("Admin_Affiliation", "Add_godchild")}
        </button>
    );
};

export default AffiliationModal;
