export interface IEcosystem {
    title: string;
    description: string;
    photo?: File;
    link: string;
}

export default class Ecosystem {
    id?: number;
    rank!: number;
    title!: string;
    description!: string;
    photo?: string;
    created_at?: Date;
    updated_at?: Date;
    link?: string;

    constructor(title: string, description: string) {
        this.title = title;
        this.description = description;
    }
}
