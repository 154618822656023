import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import RoutesNames from "../../../Services/RoutesNames";

const HeaderMenu = () => {
    const { translate } = translator();
    return (
        <ul className="flex flex-wrap text-sm font-medium md:w-max lg:w-max xl:w-max ul-bg-mode text-center text-gray-500 border-gray-200 rounded-t-lg">
            <li className="">
                <NavLink
                    to={RoutesNames.Accounting.Balance}
                    aria-current="page"
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Balances comptes")}
                </NavLink>
            </li>
            <li className="">
                <NavLink
                    to={RoutesNames.Accounting.Fee}
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Frais collectés")}
                </NavLink>
            </li>
            {/* <li className="">
                <NavLink
                    to={RoutesNames.Accounting.Balance}
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Gains Agents")}
                </NavLink>
            </li>

            <li className="">
                <NavLink
                    to={RoutesNames.Accounting.Balance}
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Transaction Cryptos")}
                </NavLink>
            </li>
            <li className="">
                <NavLink
                    to={RoutesNames.Accounting.Balance}
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Masses Monetaire")}
                </NavLink>
            </li> */}
        </ul>
    );
};

const AccountingBase = () => {
    const { translate } = translator();
    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <KpBreadCrumb
                    title={translate("ACCOUNT_MENU", "ACCOUNTING")}
                ></KpBreadCrumb>

                <div className="grid grid-cols-12 gap-6 mt-7">
                    <HeaderMenu />
                    <Outlet />
                </div>
            </div>
        </main>
    );
};

export default AccountingBase;
