export default class NotificationModel {
    id!: number;
    title!: string;
    description!: string;
    is_seen!: boolean;
    created_at!: Date;
    updated_at!: Date;

    constructor(title: string, description: string, is_seen: boolean) {
        this.title = title;
        this.description = description;
        this.is_seen = is_seen;
    }
}
