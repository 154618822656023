import { Outlet } from "react-router";
import Head from "./Head";
import Side from "./Side";
import SideMobile from "./SideMobile";
import LoadingBar from "react-top-loading-bar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Base = () => {
    const location = useLocation();

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    return (
        <div>
            <LoadingBar color="#daa520" progress={0} />
            <SideMobile />
            <Head />
            <div className="mdg-section flex md:mt-0 md-pb-20">
                <Side />
                <div className="content mdg-content h-screen overflow-y-scroll w-content !z-0 !rounded-none">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Base;
