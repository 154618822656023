import { NavLink } from "react-router-dom";
import UtilsService from "../../Services/UtilsService";
import DropdownLanguage from "../../Front/Components/Theme/DropdownLanguage";
import ThemeToggle from "../../Front/Components/Theme/ThemeToggle";

const SideMobile = () => {
    return (
        <div className="top-bar mdg-nav-bar mdg-theme-nav !inline-flex !fixed bg-black w-full !border-0 z-[10000]">
            <nav aria-label="breadcrumb" className="-intro-x mr-auto  sm:flex ">
                <div className="p-4 sm:w-64">
                    <NavLink
                        to="/"
                        className="active mdg-logo mx-auto !mt-[-10px] flex h-10 w-full justify-center text-orange-500 hover:text-yellow-500"
                        aria-current="page"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo-Kalypay-HD-Original-PNG.png",
                            )}
                            className="h-[50px] md:h-[50px] w-auto"
                            alt="Kalypay Logo"
                        />
                    </NavLink>
                </div>
            </nav>
            <div className="flex mr-4 md:!pr-[0px]">
                <div className="intro-x relative mr-2  language">
                    <DropdownLanguage align="left" />
                </div>
                <div className="flex items-center justify-end">
                    <div className="intro-x dropdown mr-2 flex h-7 w-5 items-center">
                        <ThemeToggle />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideMobile;
