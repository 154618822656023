import Select from "react-select";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import useTheme from "../../../Services/Hooks/useTheme";

interface SelectInputProps {
    id?: string;
    options: any;
    components?: any;
    value?: any;
    onChange?: (value: any) => void;
    required?: boolean;
    defaultValue?: any;
    isMulti?: boolean;
}

const SelectInput = ({
    id,
    options,
    components,
    value,
    onChange,
    required,
    defaultValue,
    isMulti,
}: SelectInputProps) => {
    const { mode } = useTheme();
    return (
        <Select
            id={id}
            options={options}
            className="bg-select-view-other"
            components={components}
            value={value}
            onChange={onChange}
            styles={{
                control: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              borderRadius: "0.5rem",
                              borderColor: "#E2E8F0",
                              background: "transparent",
                              color: "rgb(30 41 59)",
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              borderRadius: "0.5rem",
                              borderColor: "#595c5c",
                              background: "transparent",
                              color: "#FFF",
                          },
                option: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              background: "#fff",
                              color: "#000",
                              "&:hover": {
                                  color: "#fff",
                                  background: "#daa520",
                              },
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              background: "#0f0f0f",
                              color: "#FFF",
                              "&:hover": {
                                  color: "#fff",
                                  background: "#daa520",
                              },
                          },
            }}
            required={!!required}
            defaultValue={defaultValue}
            isMulti={!!isMulti}
        />
    );
};

export default SelectInput;
