export default class Option {
    label: string;
    value: string;
    image?: string;
    style?: any;

    constructor(label: string, value: string, image: string, style?: any) {
        this.label = label;
        this.value = value;
        this.image = image;
        this.style = style;
    }
}
