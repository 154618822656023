import { useState } from "react";
import { NavLink } from "react-router-dom";
import ChevronDownSvg from "../../../Components/Extra/Svg/ChevronDownSvg";
import translator from "../../../Components/Extra/Translation/Translate";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    childs: ItemListProps[];
};

const ItemListComponent = ({ name, icon, childs, path }: ItemListProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { translate } = translator();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };
    return (
        <li>
            <NavLink
                to={childs && childs.length > 0 ? `javascript:void(0)` : path}
                className={(navData) =>
                    navData.isActive
                        ? expanded
                            ? "side-menu side-menu--open"
                            : "side-menu side-menu--active"
                        : "side-menu"
                }
                onClick={(e) => {
                    toggleExanded(e);
                }}
            >
                <div className="side-menu__icon">{icon}</div>
                <div className="side-menu__title">
                    {translate(".", name)}
                    {childs && childs.length > 0 && (
                        <div
                            className={
                                !expanded
                                    ? "side-menu__sub-icon"
                                    : "side-menu__sub-icon rotate-180"
                            }
                        >
                            <ChevronDownSvg />
                        </div>
                    )}
                </div>
            </NavLink>
            {childs && childs.length > 0 && (
                <ul
                    className={expanded ? "side-menu__sub-open block pl-3" : ""}
                >
                    {childs.map((child, i) => (
                        <ItemListComponent
                            key={i}
                            name={child.name}
                            icon={child.icon}
                            path={child.path}
                            childs={child.childs}
                        ></ItemListComponent>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default ItemListComponent;
