import { NavLink } from "react-router-dom";
import EvenmentSvg from "../../Components/Extra/Svg/EvenmentSvg";
import MarketingSvg from "../../Components/Extra/Svg/MarketingSvg";
import PachBoosterSvg from "../../Components/Extra/Svg/PachBoosterSvg";
import RankSvg from "../../Components/Extra/Svg/RankSvg";
import SupportSvg from "../../Components/Extra/Svg/SupportSvg";
import translator from "../../Components/Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";
import AccountMenu from "./MenuList";
import ItemListComponent from "./Nav/ItemList";

const Side = () => {
    const { user } = useAuthentication();
    const { translate } = translator();

    return (
        <nav className="side-nav intro-x mdg-side-nav h-screen overflow-y-scroll scrollbar-hidden pt-[60px]">
            <div className="mb-6"></div>
            <div className="mb-2 text-white ">Manage</div>
            <ul>
                {AccountMenu.map((menu, i) => (
                    <ItemListComponent
                        key={i}
                        name={menu.name}
                        icon={menu.icon}
                        path={menu.path}
                        childs={menu.childs}
                    />
                ))}
                <li className="side-nav__devider my-6"></li>
                <div className="mb-2 text-white ">Finances</div>
                <li>
                    <NavLink
                        to="/earning"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <RankSvg />
                        </div>
                        <div className="side-menu__title">Earning</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="#" className="side-menu">
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                width="24"
                                height="24"
                                className="text-white icon-gouvernance"
                            >
                                <path d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3zM279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            Borrowing
                            <span className="text-xs ml-1">(Coming Soon)</span>
                        </div>
                    </NavLink>
                </li>
                <li className="side-nav__devider my-6"></li>
                <div className="mb-2 text-white ">Supports</div>
                <li>
                    <NavLink
                        to="/loyalty"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <PachBoosterSvg />
                        </div>
                        <div className="side-menu__title">
                            {translate("Admin_Affiliation", "Loyalty")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/settings/general"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="settings"
                                data-lucide="settings"
                                className="lucide lucide-settings"
                            >
                                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Profil", "Setting_Security")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/governances"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                className="text-white icon-gouvernance"
                            >
                                <path d="M18 13h-.68l-2 2h1.91L19 17H5l1.78-2h2.05l-2-2H6l-3 3v4c0 1.1.89 2 1.99 2H19c1.1 0 2-.89 2-2v-4l-3-3zm-1-5.05l-4.95 4.95-3.54-3.54 4.95-4.95L17 7.95zm-4.24-5.66L6.39 8.66c-.39.39-.39 1.02 0 1.41l4.95 4.95c.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41L14.16 2.3c-.38-.4-1.01-.4-1.4-.01z" />
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Governance", "Governance")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/find-agent"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                className="text-white icon-gouvernance"
                            >
                                <path d="M18 13h-.68l-2 2h1.91L19 17H5l1.78-2h2.05l-2-2H6l-3 3v4c0 1.1.89 2 1.99 2H19c1.1 0 2-.89 2-2v-4l-3-3zm-1-5.05l-4.95 4.95-3.54-3.54 4.95-4.95L17 7.95zm-4.24-5.66L6.39 8.66c-.39.39-.39 1.02 0 1.41l4.95 4.95c.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41L14.16 2.3c-.38-.4-1.01-.4-1.4-.01z" />
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Agent", "Find_Agent")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <a
                        href="https://kalypay.com/faq/"
                        target="_blank"
                        className="side-menu"
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-help-circle"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Setting_Option", "Help_Center")}
                        </div>
                    </a>
                </li>
                <li className="my-6"></li>
            </ul>
        </nav>
    );
};

export default Side;
