import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

const SettingSecurityHeader = () => {
    const { translate } = translator();
    const { isMerchant, isAgent } = useAuthentication();
    return (
        <ul className="flex flex-wrap text-sm font-medium md:w-max lg:w-max xl:w-max ul-bg-mode text-center text-gray-500 border-gray-200 rounded-t-lg">
            <li className="">
                <NavLink
                    to="general"
                    aria-current="page"
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Setting")}
                </NavLink>
            </li>
            <li className="">
                <NavLink
                    to="security"
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Security")}
                </NavLink>
            </li>
            <li className="">
                <NavLink
                    to="referrals"
                    className={(navData) =>
                        navData.isActive
                            ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                            : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                    }
                >
                    {translate("Profil", "Affiliation")}
                </NavLink>
            </li>

            {isAgent && (
                <li className="">
                    <NavLink
                        to="position"
                        className={(navData) =>
                            navData.isActive
                                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                        }
                    >
                        {translate("Profil", "Position")}
                    </NavLink>
                </li>
            )}
            {isMerchant && (
                <>
                    <li className="">
                        <NavLink
                            to="qr-code-merchant"
                            className={(navData) =>
                                navData.isActive
                                    ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                    : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                            }
                        >
                            {translate("Profil", "QR_Code_Merchant")}
                        </NavLink>
                    </li>
                    <li className="">
                        <NavLink
                            to="application-web"
                            className={(navData) =>
                                navData.isActive
                                    ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                    : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                            }
                        >
                            {translate("Profil", "Application_Web")}
                        </NavLink>
                    </li>
                </>
            )}
        </ul>
    );
};

const SettingBase = () => {
    const { translate } = translator();

    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <div className="intro-y mt-5 mb-2 items-center">
                    <h2 className="mr-auto text-2xl font-medium !capitalize">
                        {translate("Profil", "Setting_Security")}
                    </h2>
                    <hr className="mdg-bg-gardient-mode mt-2 h-1 md:w-80 lg:w-80 xl:w-80 2xl:w-80 rounded-[20px]" />
                </div>
                <div className="intro-y mt-2 flex items-center"></div>
                <div className="pt-5">
                    <SettingSecurityHeader />
                    <div className="intro-y col-span-12 box !shadow-none">
                        <div className="border-b border-slate-200/60 lg:flex-row relative px-3">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SettingBase;
