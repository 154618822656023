import React from "react";
import ColdWalletContent from "./ColdWalletContent";
import translator from "../../../Components/Extra/Translation/Translate";

const ColdWalletDetail = () => {
    const { translate } = translator();

    return (
        <main className="grow ">
            <div className="lg:relative lg:flex bg-white dark:bg-slate-900">
                <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                    <div className="intro-y mt-8 mb-2 items-center">
                        <h2 className="mr-auto text-lg font-medium">
                            {translate("ColdWallet", "ColdWallet")}
                        </h2>
                        <hr className="mdg-bg-gardient-mode mt-2 h-1 md:w-[300px] rounded-[20px] sm:w-[50px]" />
                    </div>
                    <div className="mt-7 px-3 py-5 box">
                        <ColdWalletContent />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ColdWalletDetail;
