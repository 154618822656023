import User from "./User";

export interface IColdWalletOrderPayload {
    quantity: number;
    country: string;
    city: string;
    postal_code: string;
    address: string;
    phone_number: string;
    wallet: string;
}

export enum ColdWalletOrderStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export default class ColdWalletOrder {
    id!: number;
    quantity!: number;
    country!: string;
    city!: string;
    postal_code!: string;
    address!: string;
    unit_price!: number;
    user: User;
    status: string;
    phone_number: string;
    created_at?: Date;
    updated_at?: Date;

    constructor(
        id: number,
        quantity: number,
        country: string,
        city: string,
        postal_code: string,
        address: string,
        unit_price: number,
        user: User,
        status: string,
        phone_number: string,
    ) {
        this.id = id;
        this.quantity = quantity;
        this.country = country;
        this.city = city;
        this.postal_code = postal_code;
        this.address = address;
        this.unit_price = unit_price;
        this.user = user;
        this.status = status;
        this.phone_number = phone_number;
    }
}
