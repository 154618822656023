import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
import UserCard from "../../../Models/UserCard";
import { UserCryptoWallet } from "../../../Models/UserWallet";
import useTheme from "../../../Services/Hooks/useTheme";
import UtilsService from "../../../Services/UtilsService";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";

export interface KpSelectOption {
    label: string;
    value: string;
    image?: string;
    style?: any;
    solde?: string;
    type?: string;
}

interface KpSelectProps {
    id?: string;
    label: string;
    options: KpSelectOption[];
    className?: string;
    placeholder?: string;
    required?: boolean;
    isMulti?: boolean;
    name: string;
    onChange?: (val: any) => void;
}

const OptionImage: React.FC<KpSelectOption> = (props) => {
    return (
        <div className="flex justify-between items-center w-full">
            <span className="flex">
                {props.image && (
                    <img src={props.image} style={props.style || {}} />
                )}
                <span className="self-center">{props.label}</span>
            </span>
            <span className="self-end">
                {props.solde && <span className="right-0">{props.solde}</span>}
            </span>
        </div>
    );
};

const SingleOption = (props: any) => {
    return (
        <components.SingleValue {...props} className="capitalize">
            <OptionImage {...props.data} />
        </components.SingleValue>
    );
};

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} className="bg-select-view capitalize">
            <OptionImage {...props.data} />
        </components.Option>
    );
};

const styleCrypto = {
    width: 25,
    height: 25,
    marginRight: 10,
    borderRadius: "50%",
};

export const createSelectOption = (
    currentAsset: UserCryptoWallet | undefined,
    type: "crypto" | "gateway",
): KpSelectOption => {
    const option: KpSelectOption = {
        label: currentAsset ? currentAsset.abbreviation : "",
        value: currentAsset ? currentAsset.abbreviation : "",
        image: currentAsset ? UtilsService.getPhotoUrl(currentAsset.logo) : "",
        style: type === "crypto" ? styleCrypto : undefined,
        solde: currentAsset
            ? UtilsService.formatAmountV2(
                  currentAsset.wallet.solde,
                  currentAsset.type === "fiat" ? 2 : 8,
              )
            : "",
        type: currentAsset ? currentAsset.type : "",
    };

    return option;
};

export const createSelectOptionWithoutImg = (data: any): KpSelectOption => {
    const option: KpSelectOption = {
        label: data ? data.label : "",
        value: data ? data.value : "",
        image: "",
        style: undefined,
        solde: "",
    };

    return option;
};

export const createCardSelectOption = (
    card: UserCard | undefined,
): KpSelectOption => {
    const option: KpSelectOption = {
        label: card ? card.last_4_digits : "",
        value: card ? card.last_4_digits : "",
        image: card ? "" : "",
        style: styleCrypto,
        solde: card ? UtilsService.formatAmountV2(card.last_balance, 2) : "",
    };

    return option;
};

const KpSelect = ({
    id,
    label,
    options,
    required = false,
    isMulti,
    name,
    placeholder,
}: KpSelectProps) => {
    const { mode } = useTheme();

    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { ref, ...rest } = register(name);
    const { field } = useController({
        name,
    });

    return (
        <div className="relative mt-3 w-full group w-selects">
            <Select
                {...rest}
                id={id ?? ""}
                options={options}
                className="bg-select-view-other"
                components={{
                    Option: CustomOption,
                    SingleValue: SingleOption,
                }}
                name={name}
                value={field.value}
                onChange={field.onChange}
                isSearchable={true}
                placeholder={placeholder ? placeholder : "Select"}
                styles={{
                    control: (base) =>
                        mode === AppMode.LIGHT
                            ? {
                                  ...base,
                                  minHeight: 46,
                                  borderRadius: "0.5rem",
                                  borderColor: errors[name] ? "red" : "#E2E8F0",
                                  background: "transparent",
                                  color: "rgb(30 41 59)",
                                  "input:focus": {
                                      boxShadow: "none",
                                  },
                              }
                            : {
                                  ...base,
                                  minHeight: 46,
                                  borderRadius: "0.5rem",
                                  borderColor: errors[name] ? "red" : "#595c5c",
                                  background: "transparent",
                                  color: "#FFF",
                                  "input:focus": {
                                      boxShadow: "none",
                                  },
                              },
                    option: (base) =>
                        mode === AppMode.LIGHT
                            ? {
                                  ...base,
                                  minHeight: 46,
                                  background: "#fff",
                                  color: "#000",
                                  "&:hover": {
                                      color: "#fff",
                                      background: "#daa520",
                                  },
                              }
                            : {
                                  ...base,
                                  minHeight: 46,
                                  background: "#0f0f0f",
                                  color: "#FFF",
                                  "&:hover": {
                                      color: "#fff",
                                      background: "#daa520",
                                  },
                              },
                }}
                isMulti={!!isMulti}
            />
            {label && (
                <label
                    htmlFor={id ?? ""}
                    className="absolute top-2 left-1  origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                >
                    {label}
                </label>
            )}
            {errors[name] && (
                <p
                    id={`${name}-error`}
                    className="tw-ml-1 tw-mt-1 tw-text-sm tw-text-red-500"
                >
                    {errors[name]?.message as any}
                </p>
            )}
        </div>
    );
};

export default KpSelect;
