import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import Card from "./Card";
import translator from "../../../../Components/Extra/Translation/Translate";
import useLanguage from "../../../../Services/Hooks/useLanguage";
import Ecosystem from "../../../../Models/Ecosystem";
import EcosystemService from "../../../../Services/EcosystemService/EcosystemService";

const style = {
    width: "100%",
};

export interface Item {
    id: number;
    text: string;
}

export interface ContainerState {
    cards: Item[];
}

const Container: React.FC = () => {
    const { translate } = translator();
    const { language } = useLanguage();
    const [draged, setDraged] = useState<number>();
    const [cards, setCards] = useState<Ecosystem[]>([]);
    const getAll = async () => {
        try {
            const res = await EcosystemService.getAll();
            setCards(res);
        } catch (err: any) {}
    };

    // const [cards, setCards] = useState(listpack);

    const moveCard = useCallback(
        async (dragIndex: number, hoverIndex: number) => {
            setCards((prevCards: Ecosystem[]) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex] as Ecosystem],
                    ],
                }),
            );
        },
        [],
    );

    useEffect(() => {
        getAll();
    }, []);
    const renderCard = useCallback((card: Ecosystem, index: number) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                ecosystem={card}
                moveCard={moveCard}
                getDraged={getDraged}
            />
        );
    }, []);

    const getDraged = (rank: number) => {
        setDraged(rank);
    };

    const sendOrderList = async () => {
        const ids = cards.map((item: Ecosystem) => item.id);
        if (cards && ids.length > 0) {
            await EcosystemService.orderList(JSON.stringify(ids));
        }
    };

    useEffect(() => {
        sendOrderList();
    }, [draged]);

    return (
        <>
            <div style={style}>
                <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                    <table className="table table-report w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-center whitespace-nowrap w-[20%]">
                                    Image
                                </th>
                                <th className="text-center whitespace-nowrap w-[20%]">
                                    {translate("Admin_Notice", "Tilte")}
                                </th>
                                <th className="text-center whitespace-nowrap w-[30%]">
                                    {translate("Admin_Notice", "external_link")}
                                </th>
                                <th className="text-center whitespace-nowrap w-[30%]">
                                    ACTIONS
                                </th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>
                {cards.map((card, i) => renderCard(card, i))}
            </div>
        </>
    );
};

export default Container;
