const VuSvg = () => {
    return (
        <svg
            style={{ color: "rgb(35, 126, 17)" }}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-check2-all"
            viewBox="0 0 16 16"
        >
            {" "}
            <path
                d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"
                fill="#237e11"
            ></path>{" "}
            <path
                d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"
                fill="#237e11"
            ></path>{" "}
        </svg>
    );
};

export default VuSvg;
