import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./Auth.reducer";
import ThemeReducer from "./Theme.reducer";
import LanguageReducer from "./Language.reducer";

const reducers = combineReducers({
    auth: AuthReducer,
    theme: ThemeReducer,
    language: LanguageReducer,
});

export default reducers;
