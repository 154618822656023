import { useAppDispatch, useAppSelector } from "../../globalState/hook";
import {
    AppMode,
    ChangeAppMode,
} from "../../globalState/reducers/Theme.reducer";

export default function useTheme() {
    const dispatch = useAppDispatch();
    const { mode } = useAppSelector((store) => store.theme);

    const setAppMode = (mode: AppMode) => {
        return dispatch(ChangeAppMode({ mode }));
    };

    return {
        mode,
        setAppMode,
    };
}
