import "animate.css";
import { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import useTheme from "../../../Services/Hooks/useTheme";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";

const ThemeMobile = () => {
    const { mode, setAppMode } = useTheme();
    const [modeValue, setModeValue] = useState<boolean>(mode != AppMode.LIGHT);

    const changeMode = (value: boolean) => {
        setModeValue(value);
        const newTheme = value ? AppMode.DARK : AppMode.LIGHT;
        setAppMode(newTheme);
    };

    return (
        <div className="toggle colour">
            <input
                id="check3"
                className="toggle-checkbox hidden"
                type="checkbox"
                checked={modeValue}
                onChange={(e) => changeMode(e.target.checked)}
            />
            <label
                htmlFor="check3"
                className="toggle-label block w-12 h-6 rounded-full transition-color duration-150 ease-out"
            ></label>
        </div>
    );
};

export default ThemeMobile;
