import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import FileInput from "../../../Components/Extra/FileInput";
import alert_message from "../../../Services/Alert/AlertMessage";
import translator from "../../../Components/Extra/Translation/Translate";
import NoticeService from "../../../Services/Notice/NoticeService";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { IEcosystem } from "../../../Models/Ecosystem";
import EcosystemService from "../../../Services/EcosystemService/EcosystemService";

interface NoticeFormProps {
    id?: number;
}

const EcosystemForm = ({ id }: NoticeFormProps) => {
    const { translate } = translator();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const { asset } = location.state || {};
    const [title, setTitle] = useState<string>(asset?.title);
    const [description, setDescription] = useState<string>(asset?.description);
    const [link, setLink] = useState<string>(asset?.link);
    const [logo, setLogo] = useState<File>();
    const [logoUrl, setLogoUrl] = useState<string>(asset?.photo);

    const createNotice = (notice: IEcosystem) => {
        const create = EcosystemService.create(notice);
        alert_message
            .promise(
                translate("Admin_Notice", "Creation_Information_Progress"),
                create,
            )
            .then(() => {
                navigate("/admin/ecosystem");
            });
    };

    const updateNotice = (id: number, notice: IEcosystem) => {
        const update = EcosystemService.update(id, notice);
        alert_message
            .promise(translate("Admin_Notice", "Update_In_Progress"), update)
            .then(() => {
                navigate("/admin/ecosystem");
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const notice: IEcosystem = {
            title: title,
            description: description,
            photo: logo,
            link: link,
        };

        if (id) {
            updateNotice(id, notice);
        } else {
            createNotice(notice);
        }
    };

    const getNotice = async () => {
        if (id && !asset) {
            try {
                const res = await NoticeService.getById(Number(id));
                setTitle(res.title);
                setDescription(res.description);
            } catch (err: any) {
                alert_message.error(err);
                setTimeout(() => navigate("/admin/ecosystem/"), 3000);
            }
        }
    };

    useEffect(() => {
        getNotice();
    }, [id]);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 p-4">
                    <div className="grid grid-cols-12 gap-6 box">
                        <div className="col-span-12 mt-8 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                            <div className="flex justify-center items-center p-2">
                                <div className="w-full h-auto image-fit">
                                    <FileInput
                                        file={logo}
                                        defaultUrl={logoUrl}
                                        setFile={setLogo}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-8 sm:col-span-12 lg:col-span-9 2xl:col-span-9 mx-4">
                            <div className="relative z-0 w-full group">
                                <input
                                    type="text"
                                    id="asset-name"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                    required
                                />
                                <label
                                    htmlFor="asset-name"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Admin_Notice", "Title")}
                                </label>
                            </div>
                            <div className="relative z-0 w-full group mt-3">
                                <input
                                    type="text"
                                    id="description"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                    required
                                />
                                <label
                                    htmlFor="description"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Admin_Notice", "Description")}
                                </label>
                            </div>
                            <div className="relative z-0 w-full group mt-3">
                                <input
                                    type="text"
                                    id="link"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value);
                                    }}
                                    required
                                />
                                <label
                                    htmlFor="link"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Admin_Notice", "Link")}
                                </label>
                            </div>
                        </div>
                        <div className="col-span-12 mx-4">
                            <div className="text-right mb-2">
                                <SaveBouton htmlType="submit">
                                    {id
                                        ? translate("Admin_Notice", "Edit")
                                        : translate(
                                              "Admin_Notice",
                                              "Save",
                                          )}{" "}
                                </SaveBouton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EcosystemForm;
