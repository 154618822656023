export interface IMerchantCategory {
    name: string;
}

export default class MerchantCategory {
    id!: number;
    name!: string;
    created_at?: Date;
    updated_at?: Date;

    constructor(name: string) {
        this.name = name;
    }
}
