import Notifications from "../Components/Theme/DropdownNotifications";
import ThemeToggle from "../Components/Theme/ThemeToggle";
import { KalypayLogo } from "../Components/Logo/Logo";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { useAppProvider } from "../Provider/AppProvider";
import DropdownProfile from "../Components/Theme/UserMenu";
import DropdownLanguage from "../Components/Theme/DropdownLanguage";

const Header = () => {
    const { user } = useAuthentication();
    const { sidebarOpen, setSidebarOpen } = useAppProvider();

    return (
        <header className="sticky top-0 bg-[#080808] border-b border-slate-200 dark:border-slate-700 z-[99]">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    {/* Header: Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="text-slate-500 hover:text-slate-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSidebarOpen(!sidebarOpen);
                            }}
                        >
                            <KalypayLogo className="h-[38px]  md:hidden" />
                        </button>
                        <button
                            className="text-slate-500 hover:text-slate-600 hidden kl-show-on"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSidebarOpen(!sidebarOpen);
                            }}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>

                    {/* Header: Right side */}
                    <div className="flex items-center space-x-3">
                        <Notifications align="right" />
                        <ThemeToggle />
                        <DropdownLanguage />
                        {/*  Divider */}
                        <hr className="w-px h-6 bg-slate-400 dark:bg-slate-700 border-none" />
                        <DropdownProfile align="right" />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
