import UtilsService from "../../Services/UtilsService";

const Loader = () => {
    return (
        <div className="fixed z-[2000] flex h-[100%] w-[100%] items-center justify-center bg-[#0f0f0f]">
            <div
                id="id-loader"
                className="before:animation-[spin_3s_linear_infinite] after:animation-[spin_1s_linear_infinite] before:absolute before:inset-[5px] before:rounded-[50%] before:border-[3px] before:border-transparent
                before:border-t-[#00f49e] after:absolute after:inset-[5px] after:rounded-[50%] after:border-[3px] after:border-transparent after:border-t-[#00fbcd]
                "
            >
                {/* <img
                    src={UtilsService.getPulicImage(
                        "/dist/image/loading_perfect-nodes.gif",
                    )}
                    alt="Loader ..."
                /> */}
            </div>
        </div>
    );
};

export default Loader;
