import DoubleLeftSvg from "../../Svg/DoubleLeftSvg";
import DoubleRightSvg from "../../Svg/DoubleRightSvg";
import OneLeftSvg from "../../Svg/OneLeftSvg";
import OneRightSvg from "../../Svg/OneRightSvg";

const DataTablePaginate = ({
    currentPage,
    setCurrentPage,
    nbPage,
    handlePaginationChange,
}: {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    nbPage: number;
    handlePaginationChange?: (page: number) => void;
}) => {
    const nbPagination = 3;
    const showPaginate: number[] = [];

    if (nbPage > 0) {
        if (currentPage == 0) {
            for (let inc = 0; inc < nbPagination; inc++) {
                if (inc < nbPage) {
                    showPaginate.push(inc);
                }
            }
        } else {
            for (
                let inc = currentPage - 1;
                inc < currentPage - 1 + nbPagination;
                inc++
            ) {
                if (inc < nbPage) {
                    showPaginate.push(inc);
                }
            }
        }
    }

    return (
        <nav className="w-full sm:w-auto">
            <ul className="pagination">
                <li className="page-item">
                    <button
                        className="page-link w-full"
                        onClick={() => {
                            setCurrentPage(0);
                            if (handlePaginationChange)
                                handlePaginationChange(0);
                        }}
                        disabled={currentPage === 0}
                    >
                        <DoubleLeftSvg />
                    </button>
                </li>

                <li className="page-item">
                    <button
                        className="page-link w-full"
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                            if (handlePaginationChange)
                                handlePaginationChange(currentPage - 1);
                        }}
                        disabled={currentPage === 0}
                    >
                        <OneLeftSvg />
                    </button>
                </li>

                {showPaginate.map((p) => {
                    return (
                        <li
                            className={
                                currentPage === p
                                    ? "page-item active w-full"
                                    : "page-item w-full"
                            }
                            key={`page-${p}`}
                        >
                            <button
                                className={
                                    currentPage === p
                                        ? "page-link active w-full"
                                        : "page-link w-full"
                                }
                                onClick={() => {
                                    setCurrentPage(p);

                                    if (handlePaginationChange)
                                        handlePaginationChange(p);
                                }}
                                disabled={currentPage === p}
                            >
                                {p + 1}
                            </button>
                        </li>
                    );
                })}
                <li className="page-item">
                    <button
                        className="page-link w-full"
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                            if (handlePaginationChange)
                                handlePaginationChange(currentPage + 1);
                        }}
                        disabled={currentPage === nbPage - 1}
                    >
                        <OneRightSvg />
                    </button>
                </li>
                <li className="page-item">
                    <button
                        className="page-link w-full"
                        onClick={() => {
                            setCurrentPage(nbPage - 1);
                            if (handlePaginationChange)
                                handlePaginationChange(nbPage - 1);
                        }}
                        disabled={currentPage === nbPage - 1}
                    >
                        <DoubleRightSvg />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default DataTablePaginate;
