import { DatatableFilter } from "../../../../../Models/DatatableFilter";
import ICSVUser from "../../../../../Models/UserCSV";
import UserService from "../../../../../Services/User/UserService";
import UtilsService from "../../../../../Services/UtilsService";
import * as XLSX from "xlsx";

const ExportUser = () => {
    const map = (item: any): ICSVUser => {
        const user = new ICSVUser(
            item.uid,
            `${item.last_name} ${item.first_name}`,
            item.email,
        );
        user.is_active = item.is_active ? `Active` : `Inactive`;
        user.kyc = UserService.formatKycStatus(item.kyc || "");
        user.type = item.type;

        return user;
    };

    const exportToXlsx = async () => {
        const filter: DatatableFilter = {
            page: 1,
            size: 999999,
            search: "",
        };

        const res = await UserService.getAllTUsersFilter(filter);

        if (res) {
            const assetsCSV: ICSVUser[] = res.data.map((item: any) =>
                map(item),
            );

            const setColumnWidth = (worksheet: XLSX.WorkSheet) => {
                const worksheetCol = worksheet["!cols"] || [];
                worksheetCol[0] = { wpx: 60 * 1.3 };
                worksheetCol[1] = { wpx: 50 * 1.3 };
                worksheetCol[2] = { wpx: 150 * 1.3 };
                worksheetCol[3] = { wpx: 150 * 1.3 };
                worksheetCol[4] = { wpx: 50 * 1.3 };
                worksheetCol[5] = { wpx: 50 * 1.3 };
                worksheet["!cols"] = worksheetCol;
            };

            const columns = [
                { label: "UID", key: "uid" },
                { label: "Type", key: "type" },
                { label: "Nom", key: "name" },
                { label: "Email", key: "email" },
                { label: "Status", key: "is_active" },
                { label: "KYC", key: "kyc" },
            ];

            const sheetData = [
                columns.map((column) => column.label), // Titres de colonne
                ...assetsCSV.map((item) =>
                    columns.map((column) => item[column.key as keyof ICSVUser]),
                ), // Données
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            const workbook = XLSX.utils.book_new();
            setColumnWidth(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Utilisateurs");

            // Génère un nom de fichier unique en ajoutant la date et l'heure actuelles
            const date = new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/[-:]/g, "");
            const fileName = `utilisateurs_${date}.xlsx`;

            XLSX.writeFile(workbook, fileName, { cellStyles: true });
        }
    };

    const handleExportClick = (e: any) => {
        e.preventDefault();
        exportToXlsx();
    };

    return (
        <button
            type="button"
            className="btn bg-transparent !border-none sm:w-[20%] md:w-auto lg:w-auto xl:w-auto h-[42px] mt-[5px] !p-0"
            onClick={(e) => handleExportClick(e)}
        >
            <img
                src={UtilsService.getPulicImage("/dist/image/icon_image.png")}
                className="h-full w-auto object-fill"
                alt=""
            />
        </button>
    );
};

export default ExportUser;
