import { useEffect, useMemo, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { useAppProvider } from "../Provider/AppProvider";
import { useLocation } from "react-router-dom";

const KpLoadingBar = () => {
    const { processing, setProcessing } = useAppProvider();
    const [progression, setProgression] = useState(0);

    const location = useLocation();

    useEffect(() => {
        setProcessing(true);
    }, [location.pathname]);

    const progress = useMemo(() => {
        if (processing) {
            const newValue = progression + Math.floor(Math.random() * 40 + 5);
            setTimeout(function () {
                setProgression(newValue);
            }, 1000);

            return progression < 85 ? newValue : progression;
        } else {
            if (progression != 0) {
                setTimeout(function () {
                    setProgression(0);
                }, 1000);
                return 100;
            } else {
                return 0;
            }
        }
    }, [processing, progression]);

    return <LoadingBar color="#daa520" progress={progress} />;
};

export default KpLoadingBar;
