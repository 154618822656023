import { Outlet } from "react-router-dom";
import Header from "../../layout/Header";

const PaymentBase = () => {
    return (
        <div>
            <Header></Header>
            <div>
                <Outlet></Outlet>
            </div>
        </div>
    );
};

export default PaymentBase;
