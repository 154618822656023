export default class ICSVTransaction {
    type: string;
    date: string;
    received_amount: string;
    sended_amount: string;
    wallet: string;
    crypto_out?: string;
    fee: number;
    crypto_fee?: string;
    platform?: string;
    description: string;
    label: string;

    constructor(
        type: string,
        date: string,
        received_amount: string,
        sended_amount: string,
        wallet: string,
        fee: number,
        description: string,
        label: string,
    ) {
        this.type = type;
        this.date = date;
        this.received_amount = received_amount;
        this.sended_amount = sended_amount;
        this.wallet = wallet;
        this.fee = fee;
        this.description = description;
        this.label = label;
    }
}
