import "animate.css";
import { useEffect, useState } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import SettingService from "../../../Services/Setting/SettingService";
import UtilsService from "../../../Services/UtilsService";
import { useAppProvider } from "../../Provider/AppProvider";
import CooldWalletNBR from "./CooldWalletNBR";
const ColdWalletContent = () => {
    const { user } = useAuthentication();
    const [price, setPrice] = useState<number>(0);
    const [status, setStatus] = useState<boolean>(false);
    const { translate } = translator();
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const getColdWalletConfig = async () => {
        try {
            const response = await SettingService.getColdWalletSetting();
            if (response) {
                setPrice(response.price);
                setStatus(response.status);
            }
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    useEffect(() => {
        getColdWalletConfig();
        finalizeProcessing();
    }, []);
    return (
        <>
            <div className="my-10 flex justify-center bg-adresse-gray p-5">
                <div className="w-4/5 text-bold text-xl text-center">
                    <h3 className="font-medium text-bold mb-5 text-xl text-center">
                        Cold Wallet S - Kalypay
                    </h3>
                    <p className="my-5 mx-3 text-left">
                        {translate("ColdWallet", "Description_1")}
                    </p>
                    <p className="my-5 mx-3 text-left">
                        {translate("ColdWallet", "Description_2")}
                    </p>
                    <div className="text-lg font-semibold mt-10">
                        {translate("ColdWallet", "Secure_your_assets_now")}
                    </div>
                    <div className="mb-1 md:text-lg">
                        $ {UtilsService.formatDecimal(price, 2)}
                    </div>
                    <div className="mb-1 md:text-lg">
                        + {translate("ColdWallet", "Shipping_costs_and_taxes")}
                    </div>
                    {user && (
                        <CooldWalletNBR
                            price={price}
                            status={status}
                            user={user}
                        />
                    )}
                </div>
            </div>

            <div className="tab-pane flex flex-col lg:flex-row">
                <div className="intro-y flex justify-center flex-col flex-1 text-center sm:px-10 lg:px-5 pb-10 lg:pb-0">
                    <div className="font-medium text-lg">ColdWallet S</div>
                    <div className="mt-3 lg:text-justify">
                        <p>{translate("ColdWallet", "Description_3")}</p>
                    </div>
                </div>
                <div className="intro-y flex-1 box py-2 lg:ml-5 mb-10 lg:mb-0">
                    <div className="flex justify-center items-center">
                        <div className="img-fit text-center w-[250px] h-[250px]">
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/cold-wallet/Cartes.png",
                                )}
                                alt=""
                                className="w-full h-auto rounded-lg"
                            />
                        </div>
                    </div>
                </div>
                <div className="intro-y flex-1 box py-2 lg:ml-5">
                    <div className="flex justify-center items-center">
                        <div className="img-fit text-center w-[350px] h-[350px]">
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/cold-wallet/Boite-carte.png",
                                )}
                                alt=""
                                className="w-full h-auto mt-12 rounded-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="mt-10 block sm:block md:flex lg:flex xl:flex justify-center">
                <div className="md:w-2/5 lg:w-/5 p-3 flex justify-center"></div>
                <div className="md:w-2/5 lg:w-2/5">
                </div>
            </div> */}
            <div className="mt-28 hidden justify-center bg-adresse-gray p-5">
                <div className="w-4/5 text-bold text-xl text-center">
                    {translate("ColdWallet", "Description_3")}
                </div>
            </div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 flex justify-center">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 lg:col-span-10 xl:col-span-10 mt-2">
                            <div className="intro-y mt-12 sm:mt-5">
                                <div className="sm:flex">
                                    <div className="px-8 py-12 flex flex-col justify-center flex-1">
                                        <dl className="max-w-md divide-y divide-gray-200">
                                            <div className="flex flex-col pb-3 mt-5">
                                                <dt className="text-lg font-semibold">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Watch_your_investments_in_crypto_currencies",
                                                    )}
                                                </dt>
                                                <dd className="mb-1 md:text-lg">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Quickly_observe_the_status_of_your_wallet",
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="flex flex-col py-3 mt-5">
                                                <dt className="text-lg font-semibold">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Make_cryptocurrency_transfers_by_sending_and_receiving_digital_funds",
                                                    )}
                                                </dt>
                                                <dd className="mb-1 md:text-lg">
                                                    BTC, XRP, ETH, LTC, BCH,
                                                    BNB, Horizen, USDC, ICX,
                                                    USDT, ZIL, BAND,{" "}
                                                    {translate(
                                                        "ColdWallet",
                                                        "Stable_tokens",
                                                    )}{" "}
                                                    et ERC20.
                                                </dd>
                                            </div>
                                            <div className="flex flex-col pt-3 mt-5">
                                                <dt className="text-lg font-semibold">
                                                    {translate(
                                                        "ColdWallet",
                                                        "2_1_factor_authentication",
                                                    )}{" "}
                                                </dt>
                                                <dd className="mb-1 md:text-lg">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Description_4",
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="flex flex-col pt-3 mt-5">
                                                <dt className="text-lg font-semibold">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Swap_crypto",
                                                    )}
                                                </dt>
                                                <dd className="mb-1 md:text-lg">
                                                    {translate(
                                                        "ColdWallet",
                                                        "Description_5",
                                                    )}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="md:px-20 md:py-20 flex flex-col justify-center flex-1 ">
                                        <img
                                            src={UtilsService.getPulicImage(
                                                "/dist/image/cold-wallet/Appcarte.png",
                                            )}
                                            alt=""
                                            className="md:w-[500px] md:h-[500px]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-28 flex justify-center bg-adresse-gray p-5">
                <div className="w-4/5 text-bold text-xl text-center">
                    <span className="text-lg font-semibold mb-3">
                        {translate(
                            "ColdWallet",
                            "The_construction_of_this_work_presents_an_unequaled_quality",
                        )}
                    </span>
                    <dd className="mb-1 md:text-lg">
                        <div>{translate("ColdWallet", "Description_6")}</div>
                        <div className="mt-5">
                            {translate("ColdWallet", "Description_7")}
                        </div>
                    </dd>
                </div>
            </div>
            <div className="mt-10 flex justify-center bg-adresse-gray p-5">
                <div className="w-3/5 text-bold text-xl text-center">
                    <div className="intro-y flex-1 py-2 lg:ml-5 mb-5 lg:mb-0">
                        <div className="flex justify-center items-center m-10">
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/cold-wallet/Cold-Wallet.png",
                                )}
                                alt=""
                                className="w-full h-auto rounded-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="grid grid-cols-12 gap-6 bg-adresse-gray p-5"> */}
            <div className="hidden">
                <div className="col-span-12 flex justify-center">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 lg:col-span-10 xl:col-span-10 mt-2">
                            <div className="intro-y mt-12 sm:mt-5">
                                <div className="sm:flex">
                                    <div className="px-8 py-12 flex flex-col justify-center flex-1">
                                        <dl className="max-w-md divide-y divide-gray-200">
                                            <div className="flex flex-col pb-3">
                                                <dt className="text-lg font-semibold">
                                                    {translate(
                                                        "ColdWallet",
                                                        "The_construction_of_this_work_presents_an_unequaled_quality",
                                                    )}
                                                </dt>
                                                <dd className="mb-1 md:text-lg">
                                                    <div>
                                                        {translate(
                                                            "ColdWallet",
                                                            "Description_6",
                                                        )}
                                                    </div>
                                                    <div className="mt-5">
                                                        {translate(
                                                            "ColdWallet",
                                                            "Description_7",
                                                        )}
                                                    </div>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="px-8 py-12 flex flex-col justify-center flex-1">
                                        <img
                                            src={UtilsService.getPulicImage(
                                                "/dist/image/cold-wallet/photo-2.png",
                                            )}
                                            alt=""
                                            className="w-96"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-20 flex justify-center p-5">
                <div className="w-4/5 text-bold text-xl text-center">
                    {translate("ColdWallet", "Technical_specifications")}
                </div>
            </div>
            <div className="mb-28 block sm:block md:flex lg:flex xl:flex justify-center">
                <div className="md:w-4/5 lg:w-3/5 p-3">
                    <dl className="max-w-md divide-y divide-gray-200">
                        <div className="flex flex-col pb-3 mt-2">
                            <dd className="mb-1 md:text-lg">
                                {translate(
                                    "ColdWallet",
                                    "ColdWallet_S_is_a_digital_wallet_combining_security_and_mobility",
                                )}
                            </dd>
                        </div>
                        <div className="flex flex-col py-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate("ColdWallet", "Wallet_Dimensions")}:
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                {translate("ColdWallet", "Credit_card")}
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate("ColdWallet", "Materials")}:
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                {translate(
                                    "ColdWallet",
                                    "metal_plastic_waterproof",
                                )}
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate("ColdWallet", "Button")}:
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                {translate(
                                    "ColdWallet",
                                    "1_metal_touch_button",
                                )}
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate("ColdWallet", "Battery_and_Power")}:
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                <div>
                                    {translate(
                                        "ColdWallet",
                                        "Rechargeable_battery",
                                    )}
                                </div>
                                <div>
                                    {translate("ColdWallet", "Contact_charge")}
                                </div>
                                <div>
                                    {translate("ColdWallet", "Battery_Life")}
                                </div>
                                <div>
                                    {translate("ColdWallet", "Standby_time")}
                                </div>
                                <div className="mt-2">
                                    {translate(
                                        "ColdWallet",
                                        "We_recommend_that_you_fully_charge_the_device_once_a_month",
                                    )}
                                </div>
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate(
                                    "ColdWallet",
                                    "Supported_Cryptocurrencies",
                                )}
                                :
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                Bitcoin, Bitcoin Cash, Ethereum, Litecoin,
                                Ripple, Horizen, Icon, Usdt, Usdc, Binance,
                                Stellar, Zilliqa, Band Protocol, Tokens ERC20{" "}
                                {translate(
                                    "ColdWallet",
                                    "and_many_other_cryptocurrencies",
                                )}
                                <div className="mt-2">
                                    {translate(
                                        "ColdWallet",
                                        "Each_account_includes_up_to_232_external_and_internal_Bitcoin_transaction_addresses",
                                    )}
                                </div>
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate(
                                    "ColdWallet",
                                    "Creating_Seed_Phrases",
                                )}
                                :
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                {translate(
                                    "ColdWallet",
                                    "Generate_Seed_phrases_or_import_them",
                                )}
                            </dd>
                        </div>
                        <div className="flex flex-col pt-3 mt-2">
                            <dt className="text-lg font-semibold">
                                {translate("ColdWallet", "OTP_algorithm")}
                            </dt>
                            <dd className="mb-1 md:text-lg">
                                {translate("ColdWallet", "2FA_authentication")}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </>
    );
};

export default ColdWalletContent;
