export default class PartenarUser {
    uid: string;
    user_name: string;
    last_name: string;
    first_name: string;
    email: string;
    photo?: string;
    constructor(
        uid: string,
        user_name: string,
        last_name: string,
        first_name: string,
        email: string,
    ) {
        this.uid = uid;
        this.user_name = user_name;
        this.last_name = last_name;
        this.first_name = first_name;
        this.email = email;
    }
}
