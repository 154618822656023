import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import KalypayFooter from "./KalypayFooter";

const KalypayBase = () => {
    const location = useLocation();

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    useEffect(() => {
        AOS.init({
            once: true,
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.classList.add(
                ...[
                    "font-aspekta",
                    "antialiased",
                    "bg-white",
                    "text-slate-800",
                    "font-[350]",
                ],
            );
        }

        return () => {
            if (html) {
                html.classList.remove(
                    ...[
                        "font-aspekta",
                        "antialiased",
                        "bg-white",
                        "text-slate-800",
                        "font-[350]",
                    ],
                );
            }
        };
    }, []);

    return (
        <>
            <Header />

            <main className="grow overflow-x-hidden no-scrollbar">
                <Outlet></Outlet>
            </main>

            <div className="overflow-x-hidden md:overflow-visible">
                <KalypayFooter />
            </div>
        </>
    );
};

export default KalypayBase;
