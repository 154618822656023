import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import Head from "./Head";
import SideMobile from "./SideMobile";

const Landing = ({ children }: { children: React.ReactNode }) => {
    document.body.style.padding = "0";
    const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const location = useLocation();

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    return (
        <div>
            <SideMobile />
            <Head />
            <GoogleReCaptchaProvider reCaptchaKey={key || ""}>
                {children}
            </GoogleReCaptchaProvider>
        </div>
    );
};

export default Landing;
