import { NavLink } from "react-router-dom";

export interface Ariane {
    name: string;
    path: string;
}
interface BreadCrumbProps {
    arianes: Ariane[];
}

const BreadCrumbV2 = ({ arianes }: BreadCrumbProps) => {
    return (
        <div className="top-bar">
            <nav
                aria-label="breadcrumb"
                className="-intro-x mr-auto hidden sm:flex"
            >
                <ol className="breadcrumb">
                    {arianes.map((ariane, i) => {
                        return (
                            <li
                                className={
                                    i === arianes.length
                                        ? "breadcrumb-item active"
                                        : "breadcrumb-item"
                                }
                                key={i}
                            >
                                <NavLink to={ariane.path}>
                                    {" "}
                                    {ariane.name}{" "}
                                </NavLink>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </div>
    );
};

export default BreadCrumbV2;
