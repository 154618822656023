import React from "react";

export default () => {
    const [modal, setModal] = React.useState(false);

    const [animation, setAnimation] = React.useState<string>(
        "animate__animated animate__fadeInDown",
    );

    const [modalContent, setModalContent] = React.useState(
        "I'm the Modal Content",
    );

    const fadeDown = () =>
        setAnimation("animate__animated animate__fadeInDown");
    const fadeUp = () => setAnimation("animate__animated animate__fadeOutUp");

    const handleModal = (content?: any) => {
        if (modal) {
            fadeUp();
            setTimeout(() => {
                if (content) {
                    setModalContent(content);
                    fadeDown();
                } else {
                    setModal(!modal);
                }
            }, 1000);
        } else {
            fadeDown();
            setModal(!modal);
            if (content) {
                setModalContent(content);
            }
        }
    };

    return { modal, handleModal, modalContent, animation };
};
